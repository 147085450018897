import styled from "styled-components";

import {
  BLUE,
  FormCheckbox,
  FormSelectTags,
  GREY,
} from "@patchworkhealth/web-components";

import { DatePickerComponent } from "components/Shared/DatePickerComponent";
import { Divider, Loading } from "components/Style";
import { TimesheetCardFragment } from "components/Timesheets/__generated__/Timesheets.generated";
import { AppState } from "context/AppContext.types";
import { formatTimeInTZ, parseTimeInTZ } from "helpers/momentHelpers";
import { Shift, SortedShifts } from "helpers/rostering";
import { TemplateSchedule, TemplateShift } from "icons/scheduleIcons";
import { NoBankBackground } from "icons/ShiftIcons";

import {
  RosteringScheduleBank,
  RosteringScheduleShift,
  WelcomeMessage,
} from "./components";

interface Props {
  loading1: boolean;
  store: AppState;
  openRosteringModal: (shift: Shift) => void;
  endDate: Date | null;
  handleEndDate: (date: Date | null) => void;
  checkbox: number;
  setActivityIds: (ids: string[]) => void;
  activitiesLabelForShift: (id: number, name: string | undefined) => string;
  setCheckbox: (value: number) => void;
  shifts: SortedShifts | null;
  handleShow: () => void;
  setModalDataFunc: (data: TimesheetCardFragment) => void;
}

const Schedule = ({
  loading1,
  store,
  openRosteringModal,
  endDate,
  handleEndDate,
  checkbox,
  setActivityIds,
  activitiesLabelForShift,
  setCheckbox,
  shifts,
  handleShow,
  setModalDataFunc,
}: Props) => {
  const nextShift = shifts?.nextShift || null;
  return (
    <>
      <ScheduleGrid>
        <ScheduleLeft>
          <div className="sticky__wrapper">
            <>
              <NextShift>
                <WelcomeMessage
                  startTime={nextShift?.startTime}
                  name={store?.user?.firstName?.slice(0, 13)}
                />

                {nextShift !== null ? (
                  <RosteringScheduleShift
                    openRosteringModal={openRosteringModal}
                    shift={nextShift || null}
                    activitiesLabel={activitiesLabelForShift}
                    size="small"
                  />
                ) : (
                  <TemplateShift />
                )}
              </NextShift>

              <Divider top="31" bottom="32" line="solid" />
            </>

            {/* Filters ********************************************* */}
            <div style={{ maxWidth: "245px" }}>
              <h2 className="schedule__filter">Filter Date</h2>
              <DatePickerComponent
                endDate={endDate}
                handleEndDate={handleEndDate}
                isClearable={false}
              />
              <br />
              <h2 className="schedule__filter">Activity Type</h2>
              <FormSelectTags
                name={"specialityIds"}
                onChange={(event) => {
                  event === null
                    ? setActivityIds([])
                    : setActivityIds(event.map((cc) => cc.value));
                }}
                options={shifts?.activities || []}
                placeholder={"Select Type"}
              />
            </div>
            {store.productType === 2 && (
              <>
                <Divider top="31" bottom="32" line="solid" />
                <h2
                  style={{ marginBottom: "20px" }}
                  className="schedule__filter"
                >
                  Shift Type
                </h2>

                <FormCheckbox
                  label="Show All"
                  value={checkbox === 1 && true}
                  name="selectedAllDepts"
                  action={() => setCheckbox(1)}
                />

                <FormCheckbox
                  label="Bank Shift"
                  value={checkbox === 2 && true}
                  name="selectedAllDepts"
                  action={() => setCheckbox(2)}
                />

                <FormCheckbox
                  label="Substantive Shift"
                  value={checkbox === 3 && true}
                  name="selectedAllDepts"
                  action={() => setCheckbox(3)}
                />

                <br />
              </>
            )}
          </div>
        </ScheduleLeft>

        {/* Grid Schedule Right ************************************ */}

        <ScheduleRight>
          {loading1 ? (
            <Loading />
          ) : shifts === null ? (
            <> </>
          ) : shifts?.shifts.length === 0 ? (
            <TemplatePage>
              <TemplateSchedule />
              <p>Your shifts will appear here soon.</p>
            </TemplatePage>
          ) : (
            shifts.dates.map((date, index) => {
              const diff = parseTimeInTZ(date).diff(
                parseTimeInTZ(undefined),
                "days"
              );
              const rosteredShifts =
                shifts?.shifts.filter(
                  (shift) =>
                    shift.shiftType === "Rostered" && shift.date === date
                ) || [];
              const bankShifts =
                shifts?.shifts.filter(
                  (shift) => shift.shiftType === "Bank" && shift.date === date
                ) || [];

              return (
                <div key={index}>
                  <DateDivider
                    checkbox={checkbox}
                    color={diff > 0 ? "black" : diff === 0 ? "blue" : "grey"}
                  >
                    <label>{formatTimeInTZ(date, "dddd Do MMMM")}</label>
                    <div></div>
                  </DateDivider>

                  <DayGrid show={checkbox}>
                    <div
                      style={{
                        display: checkbox === 2 ? "none" : "flex",
                        flexDirection: "column",
                      }}
                    >
                      {rosteredShifts.length === 0 ? (
                        <RosteringScheduleShift
                          openRosteringModal={openRosteringModal}
                          shift={null}
                          activitiesLabel={activitiesLabelForShift}
                          size="big"
                          checkbox={checkbox}
                        />
                      ) : (
                        rosteredShifts.map((dd, index) => {
                          return (
                            <RosteringScheduleShift
                              openRosteringModal={openRosteringModal}
                              shift={dd.shift}
                              activitiesLabel={activitiesLabelForShift}
                              size="big"
                              checkbox={checkbox}
                              key={index}
                            />
                          );
                        })
                      )}
                    </div>

                    {bankShifts.length === 0 ? (
                      <NoShifts show={checkbox} type="bank">
                        <NoBankBackground checkbox={checkbox} />
                      </NoShifts>
                    ) : (
                      <div
                        style={{ display: checkbox === 3 ? "none" : "flex" }}
                      >
                        {bankShifts.map((dd, key) => {
                          return (
                            <RosteringScheduleBank
                              key={key}
                              size="big"
                              shift={dd}
                              handleShow={handleShow}
                              setModalData={setModalDataFunc}
                              checkbox={checkbox}
                            />
                          );
                        })}
                      </div>
                    )}
                  </DayGrid>
                </div>
              );
            })
          )}
        </ScheduleRight>
      </ScheduleGrid>
    </>
  );
};

export default Schedule;

const ScheduleGrid = styled.div`
  display: grid;
  grid-gap: 34px;
  grid-template-columns: 333px auto;
  margin-top: -20px;
  padding: 0;
  width: 100%;

  h2 {
    font-weight: bold;
  }

  @media (max-width: 1154px) {
    display: block;
    padding: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex !important;
  }
`;

const ScheduleLeft = styled.div`
  margin: 0;
  position: relative !important;
  top: 0;

  label {
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-bottom: 15px !important;
  }
`;

const ScheduleRight = styled.div`
  margin: 0;
  max-width: 700px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-left: 20px;
  }

  @media (max-width: 1154px) {
    max-width: 100%;
  }
`;

const NextShift = styled.div`
  background: #ccd2d8;
  border-radius: 0 0 12px 12px;
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.05);
  color: ${GREY.ten};
  height: 231px;
  padding: 26px 22px;
  width: 333px;

  h2 {
    margin: 0;
  }

  p {
    margin-bottom: 21px;
  }

  span {
    font-weight: bold;
  }

  // TABLET SIZE *****************************************************************

  @media (max-width: 1154px) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-between;
    margin-right: 58px !important;
    width: 100%;
  }
`;

interface DateDividerProps {
  checkbox: number;
  color: string;
}

// Right side ***********************************************************
const DateDivider = styled.div<DateDividerProps>`
  align-items: center;
  display: flex;
  margin: 25px 0;
  max-width: 670px;

  width: ${(props) => (props.checkbox > 1 ? "500px" : "100%")};

  @media (max-width: 768px) {
    width: 100% !important;
  }

  label {
    color: ${(props) =>
      (props.color === "grey" && GREY.four) ||
      (props.color === "black" && GREY.eight) ||
      (props.color === "blue" && BLUE.five)};

    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  }

  div {
    border: 1px solid
      ${(props) =>
        (props.color === "grey" && GREY.three) ||
        (props.color === "black" && GREY.three) ||
        (props.color === "blue" && BLUE.five)};

    flex: 1;
    margin-left: 14px;
  }

  @media (max-width: 1154px) {
    max-width: 100%;
  }
`;

// DayGrid *************************************************************

interface DayGridProps {
  show: number;
}

const DayGrid = styled.div<DayGridProps>`
  display: grid;
  grid-gap: 20px;

  grid-template-columns: ${(props) =>
    props.show === 1 ? "repeat(auto-fill, 331px)" : "1fr"};
  margin-bottom: 50px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    width: 100%;
  }
`;

interface NoShiftsProps {
  type: string;
  show: number;
}

export const NoShifts = styled.div<NoShiftsProps>`
  display: ${(props) =>
    props.type === "rostering"
      ? props.show === 2
        ? "none"
        : "flex"
      : props.type === "bank" && props.show === 3
      ? "none"
      : "flex"};
`;

// Template Shift ************************

const TemplatePage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 50px;

  p {
    color: ${GREY.six};
    margin-top: 20px;
  }
`;
