import { useContext, useState } from "react";
import moment from "moment";

import CalendarBody from "components/Calendar/CalendarBody";
import CalendarHeader from "components/Calendar/CalendarHeader";
import {
  CalendarInputs,
  getInitialCalendarTab,
  MonthData,
} from "components/Calendar/CalendarHelpers";
import CalendarSingle from "components/Calendar/CalendarSingle";
import { useCalendarData } from "components/Calendar/hooks/useCalendarData";
import Layout from "components/Layout";
import { LeaveRecordModal } from "components/LeaveManagement";
import { useGetShiftsActivities } from "components/Schedule/hooks/useServicePlansShiftActivities";
import { ShiftModal } from "components/Shared/ShiftModal/ShiftModal";
import { AppContext } from "context/AppContext";

import "react-datepicker/dist/react-datepicker.css";

function CalendarPage() {
  /* State -------------------------------------------------------------- */

  const store = useContext(AppContext);
  const { productType, user } = store;

  const [inputs, setInputs] = useState<CalendarInputs>({
    page: "Month",
    calendarTab: getInitialCalendarTab(productType ?? 0),
    currentDate: moment(),
    openModal: false,
    selectedShift: null,
    selectedDate: null,
    selectedShiftId: null,
    showLeaveModal: false,
    selectedLeaveShift: null,
  });

  const orgId = user?.rosteringOrganisations?.[0]?.id;
  const orgRegId =
    Number(user?.rosteringOrganisationRegistrations?.[0]?.id) ?? 0;

  /* Create Calendar Data ------------------------------------------------- */

  const monthStart = inputs.currentDate
    .clone()
    .startOf("month")
    .startOf("isoWeek");
  const monthEnd = inputs.currentDate.clone().endOf("month").endOf("isoWeek");
  const startDate = monthStart.clone().startOf("isoWeek");
  const endDate = monthEnd.clone().endOf("isoWeek");
  const monthData: MonthData = [];

  let day = startDate;

  while (day.isSameOrBefore(endDate)) {
    const date = day.format("YYYY-MM-DD");
    monthData.push({
      date: date,
      bank: [],
      rota: [],
      leave: [],
    });
    day = day.clone().add(1, "day");
  }

  const calendarData = useCalendarData({
    monthStart: monthStart.format("YYYY-MM-DD"),
    monthEnd: monthEnd.format("YYYY-MM-DD"),
    monthData,
  });

  const closeModals = () => {
    setInputs({
      ...inputs,
      selectedShiftId: null,
      showLeaveModal: false,
    });
  };

  const eventIds = calendarData
    .map((day) => day.rota.map((event) => +event.id))
    .flat();
  const { activitiesForShift, activitiesLabelForShift } =
    useGetShiftsActivities({
      eventIds,
    });

  if (inputs.page === "Month") {
    return (
      <Layout headerValueText="Calendar">
        <CalendarHeader
          inputs={inputs}
          setInputs={setInputs}
          productType={productType}
        />
        <CalendarBody
          {...{ inputs, setInputs }}
          calendarData={calendarData}
          activitiesLabelForShift={activitiesLabelForShift}
        />
        {inputs.selectedShiftId && (
          <ShiftModal
            eventId={+inputs.selectedShiftId}
            onHide={closeModals}
            activities={activitiesForShift(+inputs.selectedShiftId)}
            activitiesLabelForShift={activitiesLabelForShift}
          />
        )}
        {inputs.showLeaveModal && (
          <LeaveRecordModal
            onHide={closeModals}
            leaveRecordId={inputs.selectedLeaveShift?.id ?? "0"}
            chosenOrg={{
              id: orgRegId.toString(),
              organisation: { value: orgId ?? 0 },
            }}
          />
        )}
      </Layout>
    );
  }

  return <CalendarSingle inputs={inputs} setInputs={setInputs} />;
}

export default CalendarPage;
