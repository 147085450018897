import { createContext, ReactNode, Reducer, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import {
  CurrentWorkerQuery,
  HydrationQuery,
  useCurrentWorkerQuery,
  useHydrationQuery,
  useRosteringNotificationsQuery,
  useTimeSheetsShiftsQuery,
} from "context/__generated__/AppContext.generated";
import {
  AppActions,
  AppState,
  CurrentWorker,
  RosteringNotifications,
} from "context/AppContext.types";
import { initialFunctions, initialState } from "context/AppContextInitialState";
import {
  deleteTokens,
  getTokens,
  hasJoinedCollabBank,
  hideLeadEmployerDetails,
} from "helpers/functions";

import { userObject } from "../tests/testHelpers";
import { reducer } from "./Reducer";

export const AppContext = createContext({
  ...initialState,
  ...initialFunctions,
});

interface Props {
  test: boolean;
  children: ReactNode;
}
const AppContextProvider = ({ test, children }: Props) => {
  // State **********************************************************
  const [state, dispatch] = useReducer<Reducer<AppState, AppActions>>(
    reducer,
    initialState
  );
  const history = useHistory();
  const token = getTokens();

  const [skip, setSkip] = useState(true);

  // Graphql *******************************************************

  useTimeSheetsShiftsQuery({
    skip: skip,
    onCompleted: (timesheetData) => {
      const requireAmount = [
        ...(timesheetData?.shiftsToSignOffHub ?? []),
        ...(timesheetData?.shiftsToSignOffHubOrApp ?? []),
      ].length;

      setTimesheets(requireAmount);
    },
  });

  useHydrationQuery({
    skip,
    onCompleted: (data) => {
      setHydrationFunction(data);
    },
  });

  // useExpensesClaimsTotalQuery({
  //   skip: !state.user || state.organisations.length === 0,
  //   variables: {
  //     workerId: Number(state.user?.id),
  //     organisationId: state.organisations[0],
  //   },
  //   onCompleted: (data) => {
  //     const expenses = data?.expensesClaims?.list?.length ?? 0;
  //     setExpenses(expenses);
  //   },
  // });

  const { refetch, loading: loadingWorker } = useCurrentWorkerQuery({
    notifyOnNetworkStatusChange: true,
    skip: !token || state.productType === null || state.productType < 0,
    onCompleted: ({ currentWorker }) => {
      setUserFunction(currentWorker);

      // If user is on mobile & not an AgencyWorker we want to show 'download the app' modal
      const isAgencyWorker =
        currentWorker?.agencyRegistrations &&
        currentWorker?.agencyRegistrations?.length > 0;

      setMobileModal(state.isMobile && !isAgencyWorker);

      const outstandingAgencies = currentWorker?.agencyRegistrations?.filter(
        (agencyRegistration) => agencyRegistration.confirmedAt === null
      );

      if (outstandingAgencies && !!outstandingAgencies?.length) {
        history.push("/agencies");
        toast.success("Please complete agency Registration before continuing");
      } else if (!currentWorker?.profileComplete) {
        history.push("/profile");
      }

      setSkip(false);
    },
    onError: () => {
      deleteTokens();
      window.location.reload();
    },
  });

  const { data, refetch: refetchNotifications } =
    useRosteringNotificationsQuery({
      skip: state.productType === null || state.productType === 0,
      onCompleted: () => {
        if (data?.rosteringNotifications?.nodes) {
          const copy = data.rosteringNotifications.nodes.slice();

          setNotifications(copy.reverse());
          setUnreadNotificationLength(copy.filter((row) => !row.isRead).length);
        }
      },
    });
  const setUserFunction = (user: CurrentWorkerQuery["currentWorker"]) => {
    if (user) {
      /*
        Here we want to check the user organisationRegistrations and
          - Pull out all the approved Orgs
          - Pull out all the ord Ids from normal orgs + collabBank ( these will be used to speed up shifts query )
          - Use mobile functions to determine if we are part of collab/bank and approved
      */

      if (user.rosteringOrganisationRegistrations?.length === 0) {
        setProductType(0);
      }

      const setOrgsIds: number[] = [];
      const approvedOrgsIds: number[] = [];
      const pendingOrgsIds: number[] = [];

      if (user?.organisationRegistrations) {
        const orgRegistrations = user.organisationRegistrations.reduce<
          NonNullable<
            CurrentWorkerQuery["currentWorker"]
          >["organisationRegistrations"]
        >((prev, current) => {
          let joinedCollabBank = false;
          let partOfCollab = false;

          if (current.organisation.collaborativeBank) {
            partOfCollab = !hideLeadEmployerDetails(
              current?.organisation.leadEmployer,
              current?.organisation.collaborativeBank?.usesLeadEmployerProcess
            );
            joinedCollabBank = hasJoinedCollabBank(
              current.organisation.collaborativeBank.id,
              user?.workerCollaborativeBanks || []
            );
          }

          const organisationRegistrationsData = {
            ...current,
            joinedCollabBank,
            partOfCollab,
          };

          if (!current.approved) {
            prev.unshift(organisationRegistrationsData);
            pendingOrgsIds.push(current.organisation.id);
          } else {
            approvedOrgsIds.push(current.organisation.id);
            setOrgsIds.push(current.organisation.id);
            prev.push(organisationRegistrationsData);
          }
          return prev;
        }, []);
        setApprovedOrgs(approvedOrgsIds);

        setUser({
          ...user,
          organisationRegistrations: orgRegistrations,
        });
      }

      if (user?.workerCollaborativeBanks) {
        user?.workerCollaborativeBanks.map((cc) => {
          return cc.collaborativeBank.organisations.map((dd) => {
            setOrgsIds.push(dd.id);
            return dd;
          });
        });
      }

      const uniqueOrgs = [...new Set(setOrgsIds)];
      setOrganisations(uniqueOrgs);
      setPendingOrgs(pendingOrgsIds);
    }
  };

  const startApp = () => {
    const tokens = getTokens();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      setIsMobile(true);
    }

    if (tokens) {
      if (isMobile) {
        history.push("/profile");
        setMobileVersion(true);
      }
    }
  };

  const fetchUser = () => refetch();
  const fetchNotifications = () => refetchNotifications();

  const setUser = (user: CurrentWorker) =>
    dispatch({
      type: "SET_USER",
      payload: user,
    });

  const setOrganisations = (org: number[]) =>
    dispatch({
      type: "SET_ORGANISATIONS",
      payload: org,
    });

  const toggleModalFunction = () =>
    dispatch({
      type: "TOGGLE_MODAL",
    });

  const setApprovedOrgs = (orgs: number[]) =>
    dispatch({
      type: "SET_APPROVED_ORGS",
      payload: orgs,
    });

  const setPendingOrgs = (orgs: number[]) =>
    dispatch({
      type: "SET_PENDING_ORGS",
      payload: orgs,
    });

  const setIsMobile = (isMobile: boolean) =>
    dispatch({
      type: "SET_IS_MOBILE",
      payload: isMobile,
    });

  const setMobileVersion = (param: boolean) =>
    dispatch({
      type: "SET_MOBILE_VERSION",
      payload: param,
    });

  const setProductType = (productType: number) =>
    dispatch({
      type: "SET_PRODUCT_TYPE",
      payload: productType,
    });

  const setShiftType = (shiftType: string) =>
    dispatch({
      type: "SET_SHIFT_TYPE",
      payload: shiftType,
    });

  const setNotifications = (notifications: RosteringNotifications) =>
    dispatch({
      type: "SET_NOTIFICATIONS",
      payload: notifications,
    });

  const setUnreadNotificationLength = (unreadNotificationLength: number) =>
    dispatch({
      type: "SET_UNREAD_NOTIFICATION_LENGTH",
      payload: unreadNotificationLength,
    });

  const setHydrationFunction = (state: HydrationQuery) => {
    dispatch({
      type: "HYDRATION_QUERY",
      payload: {
        specialities: state.specialities,
        grades: state.filterGrades,
        staffGroups: state.staffGroups,
        ediReferenceData: state.ediReferenceData,
      },
    });
  };

  const setExpenses = (num: number) => {
    dispatch({
      type: "SET_EXPENSES",
      payload: num,
    });
  };

  const acceptNotificationFunc = () => {
    dispatch({
      type: "ACCEPT_NOTIFICATION",
    });
  };

  // TIMESHEETS ******************

  const setTimesheets = (num: number) => {
    dispatch({
      type: "SET_TIMESHEETS",
      payload: num,
    });
  };

  // VMS ********************************

  const setAgencyRegistrationId = (state: number) => {
    dispatch({
      type: "SET_AGENCY_REGISTRATION_ID",
      payload: state,
    });
  };

  const setAgencyOnboarding = (state: boolean) => {
    dispatch({
      type: "SET_AGENCY_ONBOARDING",
      payload: state,
    });
  };

  const setConfirmAgencyInfo = (state: boolean) => {
    dispatch({
      type: "SET_CONFIRM_AGENCY_INFO",
      payload: state,
    });
  };

  const setMobileModal = (state: boolean) => {
    dispatch({
      type: "SET_MOBILE_MODAL",
      payload: state,
    });
  };

  return (
    <AppContext.Provider
      value={{
        startApp,
        fetchUser,
        fetchNotifications,
        setUser,
        setProductType,
        setIsMobile,
        setShiftType,
        setUnreadNotificationLength,
        setNotifications,
        setTimesheets,
        setAgencyRegistrationId,
        setAgencyOnboarding,
        setConfirmAgencyInfo,
        toggleModalFunction,
        acceptNotificationFunc,
        setMobileModal,
        setExpenses,

        user: test ? userObject : state.user,
        toggleModal: state.toggleModal,
        organisations: state.organisations,
        approvedOrgs: state.approvedOrgs,
        pendingOrgs: state.pendingOrgs,
        isMobile: state.isMobile,
        mobileVersion: state.mobileVersion,
        accountActivated: state.accountActivated,
        productType: state.productType,
        shiftType: state.shiftType,
        loadingWorker: loadingWorker,
        grades: state.grades,
        specialities: state.specialities,
        acceptNotification: state.acceptNotification,
        unreadNotificationLength: state.unreadNotificationLength,
        notifications: state.notifications,
        staffGroups: state.staffGroups,
        timesheets: state.timesheets,
        expenses: state.expenses,
        agencyRegistrationId: state.agencyRegistrationId,
        agencyOnboarding: state.agencyOnboarding,
        confirmAgencyInfo: state.confirmAgencyInfo,
        ediReferenceData: state.ediReferenceData,
        //
        mobileModal: state.mobileModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
