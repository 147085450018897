import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import styled from "styled-components";

import {
  ApiClaim,
  DeleteApiClaimIdRequest,
} from "@patchworkhealth/expenses-api";
import { GREY, RBRED } from "@patchworkhealth/web-components";

import {
  ExceptionReportContainer,
  ViewReportButton,
} from "components/ExceptionReports/ExceptionReports.styled";
import { useGetOrganisationsQuery } from "gql/__generated__/Queries.generated";
import { expensesApi, onReactQueryError } from "helpers/kmonoApiClient";

import { ExpensesStatus } from "./expensesHelpers";

interface Props {
  expense: ApiClaim;
}

const ExpensesTableRow = ({ expense }: Props) => {
  const queryClient = useQueryClient();

  const { data: category } = useQuery({
    queryKey: ["ExpensesCategory", expense.categoryId],
    queryFn: () =>
      expensesApi.categoryApi.getApiCategoryId({ id: expense.categoryId }),
  });

  const { data: organisations } = useGetOrganisationsQuery({
    fetchPolicy: "cache-and-network",
    skip: !category,
    variables: {
      ids: [category ? category.organisationId : 0],
    },
  });

  const organisation =
    organisations?.organisations.length === 1
      ? organisations.organisations[0].name
      : "";

  const deleteClaim = useMutation({
    mutationFn: (id: DeleteApiClaimIdRequest) =>
      expensesApi.claimApi.deleteApiClaimId(id),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["ExpensesClaims"] })
        .then(() => {
          toast.success("Expense Deleted");
        });
    },
    onError: onReactQueryError,
  });

  const handleDeleteClaim = async () => {
    deleteClaim.mutate({ id: expense.id });
  };
  return (
    <>
      <ExceptionReportContainer className="">
        <TextItem className="w-[120px]">{expense.shiftId}</TextItem>

        <TextItem className="max-w-[150px]">{category?.name}</TextItem>

        <TextItem className="w-[80px]">
          <span className="font-bold block capitalize">
            {category?.expenseType}
          </span>
          £{expense.amount.toFixed(2)}
        </TextItem>
        <TextItem className="max-w-[170px]">{organisation}</TextItem>

        <TextItem className="w-[100px]">
          {dayjs(expense.createdAt).format("Do MMM YYYY")}
        </TextItem>
        <TextItem className="w-[120px]">
          <ExpensesStatus status={expense.status ?? "Pending"} />
        </TextItem>
        <TextItem>
          {expense.status === "PENDING" && (
            <ViewReportButton
              style={{ color: RBRED.six }}
              onClick={handleDeleteClaim}
            >
              Delete
            </ViewReportButton>
          )}
        </TextItem>
      </ExceptionReportContainer>
    </>
  );
};

export default ExpensesTableRow;

export const TextItem = styled.p`
  color: ${GREY.ten};
  font-size: 12px !important;
  margin-bottom: 0;
  padding: 0 3px 0 5px;
  line-height: normal;
`;
