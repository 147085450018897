import { useContext, useEffect, useRef, useState } from "react";
import { FormGroup, FormLabel } from "react-bootstrap";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";

import { FormSelect } from "@patchworkhealth/web-components";

import {
  TimesheetCardFragment,
  useDeContractsSignVersionAssignmentMutation,
  useDeContractsVersionContentQuery,
} from "components/Timesheets/__generated__/Timesheets.generated";
import { Button, ModalContainer } from "components/UI";
import { AppContext } from "context/AppContext";
import { formatError } from "helpers/formatError";
import { classNames, formatForReactSelect } from "helpers/newHelpers";

import {
  useBookOrApplyMutation,
  useReasonsForCancellationQuery,
  useWithdrawApplicationMutation,
  useWithdrawWorkerMutation,
  useWorkerShiftQuery,
} from "./__generated__/Shifts.generated";
import { checkProvisional } from "./ShiftHelpers";
import { CloseIcon, LoadingSVG } from "./ShiftIcons";
import { PaymentDetails, ShiftDetails } from "./ShiftModalDetails";

interface JoinInputs {
  hasInput: boolean;
}

export const ShiftsModal = ({
  handleClose,
  handleSignOff,
  openModal,
  selectedShift,
}: {
  handleClose: () => void;
  openModal: boolean;
  selectedShift: TimesheetCardFragment | null;
  handleSignOff?: (shifts: TimesheetCardFragment[]) => void;
}) => {
  /* State =--------------------------------------------------------------- */

  const { user } = useContext(AppContext);
  const history = useHistory();
  const [cancelShift, setCancelShift] = useState(false);
  const [cancelReason, setCancelReason] = useState<CancelReason>(null);
  //

  const [joininputs, setJoinInputs] = useState<JoinInputs>({
    hasInput: false,
  });

  const [signDeContract, setSignDeContract] = useState(false);

  const [canvasWidth, setCanvasWidth] = useState(500);
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);
  //
  const { isBankWorker, statusCheck } = checkProvisional(user, selectedShift);

  /* Queries ------------------------------------------------------------- */

  const {
    data: { workerShift } = {},
    loading,
    refetch,
  } = useWorkerShiftQuery({
    skip: !openModal || !selectedShift,
    variables: {
      id: selectedShift?.id ?? 0,
    },
  });

  const { data: { reasonsForCancellation } = {} } =
    useReasonsForCancellationQuery({
      skip: !cancelShift,
      variables: {
        organisationId: workerShift?.organisation?.id ?? 0,
      },
    });

  const { data: data2 } = useDeContractsVersionContentQuery({
    skip: !workerShift?.deContractVersionAssignment?.deContractVersion?.id,
    variables: {
      deContractVersionId:
        workerShift?.deContractVersionAssignment?.deContractVersion?.id ?? 0,
      versionAssignmentId: workerShift?.deContractVersionAssignment?.id ?? 0,
      workerId: Number(workerShift?.agencyRegistration?.id),
    },
  });

  const showExpenses =
    workerShift?.organisation.usesExpenses &&
    [
      "BOOKED",
      "TO_SIGN_OFF_ON_HUB",
      "TO_SIGN_OFF_ON_HUB_OR_APP",
      "SIGN_OFF_REQUESTED",
      "SIGNED_OFF",
      "APPROVED",
      "PAID",
    ].includes(workerShift?.status || "NONE");

  /* Mutations -------------------------------------------------------------- */

  const [cancelWorkerShiftMutation] = useWithdrawWorkerMutation();
  const [bookWorkerShiftMutation] = useBookOrApplyMutation();
  const [withdrawWorkerMutation] = useWithdrawApplicationMutation();

  const [signContractMutation] = useDeContractsSignVersionAssignmentMutation();

  /* CancelShift ----------------------------------------------------------- */

  const cancelShiftFunction = async () => {
    const id = toast.loading("Loading");

    const { data: result } = await cancelWorkerShiftMutation({
      variables: {
        shiftId: selectedShift?.id ?? 0,
        reasonForCancellationId: Number(cancelReason?.value),
      },
    });

    if (result?.withdrawWorker?.errors?.length) {
      toast.error(formatError(result.withdrawWorker.errors), { id });
      return;
    }

    handleClose();
    toast.success("Shift Cancelled", { id });
    setCancelShift(false);
  };

  /* BookShift ------------------------------------------------------------- */

  const bookWorkerFunction = async () => {
    const id = toast.loading("Loading");

    const { data: result } = await bookWorkerShiftMutation({
      variables: { shiftId: selectedShift?.id ?? 0 },
    });

    if (result?.bookOrApply?.errors?.length) {
      toast.error(formatError(result.bookOrApply.errors), { id });
      return;
    }

    handleClose();

    if (result?.bookOrApply?.shift?.status === "PROVISIONALLY_BOOKED") {
      toast.success("Shift Applied", { id });
      refetch();
    } else {
      toast.success(`Shift ${result?.bookOrApply?.shift?.status}`, { id });
    }
    setCancelShift(false);
  };

  /* WithdrawShift ------------------------------------------------------------- */

  const withdrawWorkerFunction = async () => {
    const id = toast.loading("Loading");

    const { data: result } = await withdrawWorkerMutation({
      variables: {
        shiftId: selectedShift?.id ?? 0,
        workerId: Number(user?.id),
      },
    });

    if (result?.withdrawApplication?.errors?.length) {
      toast.error(formatError(result.withdrawApplication.errors), { id });
      return;
    }

    handleClose();
    toast.success("Shift Withdrawn", { id });
  };

  /* DE Contracts ------------------------------------------------------------- */

  const removePercentageWithinSpans = (htmlContent: string) => {
    const parser = new DOMParser();
    const serializer = new XMLSerializer();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const spans = doc.querySelectorAll("span");
    spans.forEach((span) => {
      const textContent = span.textContent || "";
      span.textContent = textContent.replace(/%/g, "");
    });

    return serializer.serializeToString(doc);
  };

  /* CANVAS STUFF ----------------------------------------  */

  const clearSignature = () => {
    sigCanvasRef.current?.clear();
    setJoinInputs({ hasInput: false });
  };

  const handleEnd = () => {
    if (!sigCanvasRef.current?.isEmpty()) {
      setJoinInputs({ hasInput: true });
    }
  };

  const calculateCanvasWidth = () => {
    const maxWidth = 500;
    const availableWidth = window.innerWidth - 80; // Assuming some padding/margin
    return Math.min(availableWidth, maxWidth);
  };

  useEffect(() => {
    const updateCanvasWidth = () => {
      setCanvasWidth(calculateCanvasWidth());
    };

    updateCanvasWidth();
    window.addEventListener("resize", updateCanvasWidth);

    return () => {
      window.removeEventListener("resize", updateCanvasWidth);
    };
  }, []);
  //  END of Canvas Stuff --------------------------------- */

  const handleAgreeStatement = async () => {
    // Display basic confirm window to proceed

    const answer = window.confirm("Please confirm this is your signature");

    if (!answer) {
      return;
    }

    const id = toast.loading("Loading");

    const signatureData = sigCanvasRef.current?.toDataURL("image/png");

    const { data: result } = await signContractMutation({
      variables: {
        id: workerShift?.deContractVersionAssignment?.id ?? 0,
        signature: signatureData || "",
      },
    });

    if (result?.deContractsSignVersionAssignment?.errors?.length) {
      toast.error(formatError(result.deContractsSignVersionAssignment.errors), {
        id,
      });
      return;
    }

    handleClose();
    toast.success("Contract Signed", { id });
    window.location.reload();
  };

  if (signDeContract && workerShift) {
    return (
      <ModalContainer size="lg" openModal={openModal} handleClose={handleClose}>
        {signDeContract && (
          <div className="p-8 border rounded-lg">
            <div
              className="p-3 my-2 overflow-auto text-[11px] border max-h-[450px] min-h-[300px] bg-grey-1/80"
              dangerouslySetInnerHTML={{
                __html: removePercentageWithinSpans(
                  data2?.deContractsVersionContent || ""
                ),
              }}
            />

            <p className="my-4 text-center">
              By Signing Below you agree to and understand the{" "}
              <strong>Direct Engagement Contract</strong>
            </p>

            <div className="relative">
              <SignatureCanvas
                ref={sigCanvasRef}
                penColor="black"
                onEnd={handleEnd}
                canvasProps={{
                  width: canvasWidth,
                  height: 140,
                  className: `w-[${canvasWidth}px] mx-auto border h-[140px] rounded-lg bg-white`,
                }}
              />

              <br />

              {joininputs.hasInput && (
                <button
                  className="absolute top-4 right-[195px]"
                  onClick={() => {
                    clearSignature();
                  }}
                >
                  Clear
                </button>
              )}
            </div>

            <div className="flex justify-between pt-4 mt-4 border-t">
              <div />
              <div className="flex">
                <Button
                  className="mr-4"
                  onClick={() => {
                    setSignDeContract(false);
                    clearSignature();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={classNames(
                    joininputs.hasInput
                      ? "bg-blue-5"
                      : "bg-grey-5 pointer-events-none",
                    "text-white"
                  )}
                  onClick={handleAgreeStatement}
                  variant="blue"
                >
                  Save & Continue
                </Button>
              </div>
            </div>
          </div>
        )}
      </ModalContainer>
    );
  }

  return (
    <ModalContainer size="sm" openModal={openModal} handleClose={handleClose}>
      <div
        className="relative flex flex-col text-sm md:flex-row "
        style={{ maxHeight: "75vh", minHeight: 540 }}
      >
        {!loading && selectedShift?.staffGroup && (
          <p className="absolute left-[310px] text-xs top-3">
            {selectedShift.staffGroup.title}
          </p>
        )}

        <CloseIcon onClick={handleClose} />
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center">
            {LoadingSVG}
          </div>
        )}

        {!loading && workerShift && (
          <>
            <ShiftDetails workerShift={workerShift} status={statusCheck} />

            <div className="relative min-h-[540px] flex-[4] border-l border-l-grey-2 bg-grey-1 p-8 md:rounded-r-xl">
              {cancelShift && (
                <>
                  <p className="p-3 mb-3 text-lg font-semibold text-center border-b border-dotted border-grey-3">
                    Are you sure you want to cancel this shift?
                  </p>

                  <FormGroup className="mb-6">
                    <FormLabel>Select cancellation reason</FormLabel>
                    <FormSelect
                      onChange={(e) => setCancelReason(e)}
                      options={formatForReactSelect(reasonsForCancellation)}
                      isClearable
                      placeholder={"Select cancellation reason"}
                      value={cancelReason}
                    />
                  </FormGroup>
                  <Button
                    onClick={cancelShiftFunction}
                    disabled={cancelReason === null}
                    fullWidth
                    variant="blue"
                  >
                    Confirm Cancellation
                  </Button>
                </>
              )}

              {!cancelShift && (
                <div>
                  <PaymentDetails workerShift={workerShift} />
                  {selectedShift?.agencyRegistration && (
                    <div className="min-h-[0px]"></div>
                  )}
                  {workerShift?.status === "APPLIED" && (
                    <Button
                      fullWidth
                      variant="red"
                      onClick={withdrawWorkerFunction}
                    >
                      Withdraw Application
                    </Button>
                  )}
                  {/* Scenarios                    
                      1. Shift is not a DE contract shift & is 'to_sign_off'
                      2. Shift is De Contract shift, has not had contract signed & is 'to_sign_off'
                      3. Shift is De Contract shift, has had contract signed & is 'to_sign_off'                    
                    */}
                  <div className="flex">
                    {showExpenses && (
                      <Button
                        onClick={() => {
                          history.push(
                            `/expenses?id=${workerShift?.id}&org=${workerShift?.organisation?.id}&staff_group=${workerShift?.organisationStaffGroup?.id}&department=${workerShift?.department?.id}`
                          );
                        }}
                        variant="default"
                        fullWidth
                        className="mr-2 mb-2"
                      >
                        Expense Claim
                      </Button>
                    )}

                    {workerShift.deContractVersionAssignment &&
                      workerShift.requiresDeContractToSign && (
                        <Button
                          icon={ContractIcon}
                          fullWidth
                          onClick={() => setSignDeContract(true)}
                          className="mb-2"
                        >
                          Sign Contract
                        </Button>
                      )}
                  </div>
                  {statusCheck === "BOOKED" && isBankWorker && (
                    <Button
                      fullWidth
                      onClick={() => setCancelShift(true)}
                      variant="blue"
                    >
                      Cancel Shift
                    </Button>
                  )}
                  {workerShift?.status.match(/TO_SIGN_OFF/) && (
                    <>
                      <Button
                        className="mb-2"
                        fullWidth
                        onClick={() => {
                          handleClose();
                          handleSignOff
                            ? handleSignOff([workerShift])
                            : history.push("/timesheets");
                        }}
                        variant="blue"
                      >
                        Sign Off
                      </Button>
                    </>
                  )}
                  {/* If Shift is not yet applied for, then we should have booking Actions either NONE or [OPTIONS] */}
                  {isBankWorker &&
                    ["URGENT", "AVAILABLE", "PROVISIONALLY_BOOKED"].includes(
                      workerShift?.status || ""
                    ) &&
                    (workerShift?.bookingAction === "NONE" ? (
                      <p className="font-semibold text-center">
                        Sorry, you can not book this shift as your grade does
                        not match the shift grade.
                      </p>
                    ) : (
                      <Button
                        onClick={bookWorkerFunction}
                        variant="blue"
                        fullWidth
                      >
                        {workerShift?.bookingAction === "APPLY"
                          ? "Apply for"
                          : "Book"}{" "}
                        Shift
                      </Button>
                    ))}
                  {workerShift?.shiftLimits?.visaAllowed === false && (
                    <p className="mt-4 text-center text-red-500 font-semibold">
                      Booking this shift may take you over your Visa working
                      hours. You can still apply but it may be rejected and
                      instant booking is unavailable
                    </p>
                  )}
                </div>
              )}

              {/*  If Contract is signed display download & Signature  */}
              {workerShift.deContractVersionAssignment?.signedAt && (
                <>
                  <Button
                    className="mt-4"
                    icon={ContractIcon}
                    fullWidth
                    onClick={() => {
                      window.open(
                        workerShift.deContractVersionAssignment
                          ?.contractFileUrl || "",
                        "_blank"
                      );
                    }}
                    variant="blue"
                  >
                    Download Contract
                  </Button>
                </>
              )}

              {/* Fraud Declaration Statement ------------------------------------------- */}

              {workerShift?.agreedFraudStatementAt && (
                <div className="absolute text-sm bottom-4 right-5">
                  <span className="font-bold">Fraud Declaration</span> agreed on
                  {` ${moment(workerShift?.agreedFraudStatementAt).format(
                    "DD/MM/YYYY"
                  )} at ${moment(workerShift?.agreedFraudStatementAt).format(
                    "HH:mma"
                  )}`}
                </div>
              )}

              {/* End ------------------------------------------------------------------ */}
            </div>
          </>
        )}
      </div>
    </ModalContainer>
  );
};

type CancelReason = {
  value: number;
  reason: string;
} | null;

const ContractIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    className="ml-2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 12H16V14H8V12ZM10 20H6V4H13V9H18V12.1L20 10.1V8L14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H10V20ZM8 18H12.1L13 17.1V16H8V18ZM20.2 13C20.3 13 20.5 13.1 20.6 13.2L21.9 14.5C22.1 14.7 22.1 15.1 21.9 15.3L20.9 16.3L18.8 14.2L19.8 13.2C19.9 13.1 20 13 20.2 13ZM20.2 16.9L14.1 23H12V20.9L18.1 14.8L20.2 16.9Z"
      fill="#829FAE"
    />
  </svg>
);
