import styled from "styled-components";

import { ApiClaim } from "@patchworkhealth/expenses-api";
import { FormSelect, Loading } from "@patchworkhealth/web-components";

import {
  ColumnHeader,
  GridContainer,
  GridHeader,
} from "components/ExceptionReports/ExceptionReports.styled";
import { ExpensesInputs } from "containers/expenses";

import { ExpensesIcon } from "./expensesHelpers";
import ExpensesTableRow from "./ExpensesTableRow";

interface Props {
  expenses: ApiClaim[];
  loading: boolean;
  inputs: ExpensesInputs;
  setInputs: (inputs: ExpensesInputs) => void;
}

const ExpensesTable = ({ expenses, loading, inputs, setInputs }: Props) => {
  if (loading)
    return (
      <Loading
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      />
    );

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div className="flex w-full justify-between">
          <h2 className="m-0 flex items-center whitespace-nowrap text-[18px] font-semibold">
            Expenses
            <span className="ml-2 inline-flex h-8 min-w-[32px] items-center justify-center rounded-lg bg-grey-1 px-2 text-sm font-semibold">
              {expenses.length ?? 0}
            </span>
          </h2>

          <FormSelect
            options={EXPENSE_CLAIM_OPTIONS}
            className="w-[250px] ml-5"
            isClearable
            placeholder={"Select ShiftType"}
            onChange={(event) => {
              setInputs({
                ...inputs,
                status: event,
              });
            }}
            value={inputs.status}
          />
        </div>
      </div>

      {!loading && expenses.length === 0 && (
        <div className=" mt-[200px] flex flex-col items-center justify-center">
          <div className="w-[300px]">
            <ExpensesIcon />
            <p className="my-6 text-center">You have no expense claims yet</p>
          </div>
        </div>
      )}

      {!loading && expenses.length > 0 && (
        <GridContainer>
          <GridHeader>
            <ColumnHeader className="pl-2 w-[120px]">Shift</ColumnHeader>
            <ColumnHeader>Expense Category</ColumnHeader>
            <ColumnHeader className="max-w-[80px]">Amount</ColumnHeader>
            <ColumnHeader className="max-w-[80px]">Org</ColumnHeader>
            <ColumnHeader className="max-w-[80px]">Submitted</ColumnHeader>
            <ColumnHeader className="max-w-[120px]">Status</ColumnHeader>
            <ColumnHeader />
          </GridHeader>

          <ScrollableContainer>
            {expenses?.map((expense) => (
              <ExpensesTableRow key={expense.id} expense={expense} />
            ))}
          </ScrollableContainer>
        </GridContainer>
      )}
    </>
  );
};

export default ExpensesTable;

export const ScrollableContainer = styled.div.attrs({ role: "row" })`
  display: block;
  // max-height: 70vh;
  overflow: auto;
`;

export const EXPENSE_CLAIM_OPTIONS = [
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Approved",
    value: "Approved",
  },

  {
    label: "Paid",
    value: "Paid",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
  {
    label: "Deleted",
    value: "Deleted",
  },
];
