import * as Types from '../../../../baseCODEGEN';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type RosterWorkersInShiftQueryVariables = Types.Exact<{
  shiftId: Types.Scalars['Int'];
}>;


export type RosterWorkersInShiftQuery = { __typename?: 'Query', rosterWorkersInShift?: Array<{ __typename?: 'RosteringShift', id: string, startHour?: number | null, startMinute?: number | null, endHour?: number | null, endMinute?: number | null, grade?: { __typename?: 'Grade', id: string, title: string, colour?: string | null } | null, eventWithPublicUserInfo?: { __typename?: 'RosteringEventWithPublicUserInfo', id: number, startsAt?: any | null, endsAt?: any | null, userId?: number | null, firstName?: string | null, lastName?: string | null, profilePictureUrl?: string | null, phoneNumber?: string | null } | null, shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null, organisationActivity?: { __typename?: 'RosteringOrganisationActivity', id: number, name: string } | null }> | null };

export type RosteringEventQueryVariables = Types.Exact<{
  eventId: Types.Scalars['Int'];
}>;


export type RosteringEventQuery = { __typename?: 'Query', rosteringEvent?: { __typename?: 'RosteringEvent', startsAt: any, endsAt: any, roster: { __typename?: 'RosteringRoster', rota: { __typename?: 'RosteringRota', name: string, id: string } }, scheduleable?: { __typename?: 'RosteringShift', id: string, workerNote?: string | null, shiftType?: { __typename?: 'RosteringShiftType', id: string, name: string } | null, eventWithPublicUserInfo?: { __typename?: 'RosteringEventWithPublicUserInfo', id: number, phoneNumber?: string | null } | null, organisationActivity?: { __typename?: 'RosteringOrganisationActivity', id: number, name: string } | null, sites?: Array<{ __typename?: 'Site', id: number, name?: string | null }> | null, locations?: Array<{ __typename?: 'Location', id: number, name: string }> | null } | null } | null };


export const RosterWorkersInShiftDocument = gql`
    query RosterWorkersInShift($shiftId: Int!) {
  rosterWorkersInShift(shiftId: $shiftId) {
    id
    startHour
    startMinute
    endHour
    endMinute
    grade {
      id
      title
      colour
    }
    eventWithPublicUserInfo {
      id
      startsAt
      endsAt
      userId
      firstName
      lastName
      profilePictureUrl
      phoneNumber
    }
    shiftType {
      id
      name
    }
    organisationActivity {
      id
      name
    }
  }
}
    `;

/**
 * __useRosterWorkersInShiftQuery__
 *
 * To run a query within a React component, call `useRosterWorkersInShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosterWorkersInShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosterWorkersInShiftQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useRosterWorkersInShiftQuery(baseOptions: Apollo.QueryHookOptions<RosterWorkersInShiftQuery, RosterWorkersInShiftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosterWorkersInShiftQuery, RosterWorkersInShiftQueryVariables>(RosterWorkersInShiftDocument, options);
      }
export function useRosterWorkersInShiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosterWorkersInShiftQuery, RosterWorkersInShiftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosterWorkersInShiftQuery, RosterWorkersInShiftQueryVariables>(RosterWorkersInShiftDocument, options);
        }
export type RosterWorkersInShiftQueryHookResult = ReturnType<typeof useRosterWorkersInShiftQuery>;
export type RosterWorkersInShiftLazyQueryHookResult = ReturnType<typeof useRosterWorkersInShiftLazyQuery>;
export type RosterWorkersInShiftQueryResult = Apollo.QueryResult<RosterWorkersInShiftQuery, RosterWorkersInShiftQueryVariables>;
export const RosteringEventDocument = gql`
    query RosteringEvent($eventId: Int!) {
  rosteringEvent(eventId: $eventId) {
    startsAt
    endsAt
    roster {
      rota {
        name
        id
      }
    }
    scheduleable {
      ... on RosteringShift {
        id
        workerNote
        shiftType {
          id
          name
        }
        eventWithPublicUserInfo {
          id
          phoneNumber
        }
        organisationActivity {
          id
          name
        }
        sites {
          id
          name
        }
        locations {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useRosteringEventQuery__
 *
 * To run a query within a React component, call `useRosteringEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosteringEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosteringEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useRosteringEventQuery(baseOptions: Apollo.QueryHookOptions<RosteringEventQuery, RosteringEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RosteringEventQuery, RosteringEventQueryVariables>(RosteringEventDocument, options);
      }
export function useRosteringEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RosteringEventQuery, RosteringEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RosteringEventQuery, RosteringEventQueryVariables>(RosteringEventDocument, options);
        }
export type RosteringEventQueryHookResult = ReturnType<typeof useRosteringEventQuery>;
export type RosteringEventLazyQueryHookResult = ReturnType<typeof useRosteringEventLazyQuery>;
export type RosteringEventQueryResult = Apollo.QueryResult<RosteringEventQuery, RosteringEventQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosterWorkersInShiftQuery((req, res, ctx) => {
 *   const { shiftId } = req.variables;
 *   return res(
 *     ctx.data({ rosterWorkersInShift })
 *   )
 * })
 */
export const mockRosterWorkersInShiftQuery = (resolver: ResponseResolver<GraphQLRequest<RosterWorkersInShiftQueryVariables>, GraphQLContext<RosterWorkersInShiftQuery>, any>) =>
  graphql.query<RosterWorkersInShiftQuery, RosterWorkersInShiftQueryVariables>(
    'RosterWorkersInShift',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRosteringEventQuery((req, res, ctx) => {
 *   const { eventId } = req.variables;
 *   return res(
 *     ctx.data({ rosteringEvent })
 *   )
 * })
 */
export const mockRosteringEventQuery = (resolver: ResponseResolver<GraphQLRequest<RosteringEventQueryVariables>, GraphQLContext<RosteringEventQuery>, any>) =>
  graphql.query<RosteringEventQuery, RosteringEventQueryVariables>(
    'RosteringEvent',
    resolver
  )
