import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { AppContext } from "context/AppContext";
import { classNames } from "helpers/newHelpers";
import { SailFlag, useFeatureFlag } from "helpers/useFeatureFlags";

import {
  AgenciesIcon,
  BankShiftsIcon,
  CalendarIcon,
  DeptRotaIcon,
  DocumentsIcon,
  ExceptionReportsIcon,
  ExpensesIcon,
  OrganisationsIcon,
  PreferencesIcon,
  ScheduleIcon,
  SelfRosteringIcon,
  TimesheetsIcon,
} from "./NavIcons";

export const useNavigationLinks = () => {
  /* State -------------------------------*/
  const store = useContext(AppContext);
  const { pathname } = useLocation();
  const showSelfRostering = useFeatureFlag(SailFlag.showSelfRostering);
  const showExpenses = [0, 2].includes(store.productType ?? -1);

  const outstandingAgencies = store.user?.agencyRegistrations?.filter(
    (agencyRegistration) => agencyRegistration.confirmedAt === null
  );

  const isMobileUser = store.isMobile;
  const productType = store.productType || 0;

  /* Return  ----------------------------*/

  return [
    {
      to: "/schedule",
      isActive: pathname === "/schedule",
      icon: <ScheduleIcon isActive={pathname === "/schedule"} />,
      label: "My schedule",
    },
    {
      to: "/calendar",
      isHidden: isMobileUser,
      isActive: pathname === "/calendar",
      icon: <CalendarIcon isActive={pathname === "/calendar"} />,
      label: "Calendar view",
    },
    {
      divider: true,
    },
    {
      to: "/shifts",
      isActive: pathname === "/shifts",
      isHidden: false,
      icon: <BankShiftsIcon isActive={pathname === "/shifts"} />,
      label: "Shifts",
    },
    {
      to: "/timesheets",
      isActive: pathname === "/timesheets",
      isHidden: false,
      icon: <TimesheetsIcon isActive={pathname === "/timesheets"} />,
      label: (
        <>
          Timesheets{" "}
          {store?.timesheets > 0 && (
            <TimesheetsAlert number={store.timesheets} />
          )}
        </>
      ),
    },
    {
      to: "/expenses",
      isActive: pathname === "/expenses",
      isHidden: !showExpenses,
      icon: <ExpensesIcon isActive={pathname === "/expenses"} />,
      label: "Expenses",
    },
    {
      divider: productType === 2,
    },
    {
      to: "/team-rota",
      isActive: pathname === "/team-rota",
      isHidden: productType === 0 || isMobileUser,
      icon: <DeptRotaIcon isActive={pathname === "/team-rota"} />,
      label: "Team rota",
    },
    {
      to: "/leave-management",
      isActive: pathname === "/leave-management",
      icon: <PreferencesIcon isActive={pathname === "/leave-management"} />,
      isHidden: productType === 0 || isMobileUser,
      label: "My leave",
    },
    {
      to: "/exception-reports",
      isActive: pathname === "/exception-reports",
      isHidden: productType === 0 || isMobileUser,
      icon: (
        <ExceptionReportsIcon isActive={pathname === "/exception-reports"} />
      ),
      label: "Exception reports",
      testid: "exception_report",
    },
    {
      to: "/roster-preferences",
      isActive: pathname === "/roster-preferences",
      isHidden: productType === 0 || !showSelfRostering || isMobileUser,
      icon: <SelfRosteringIcon isActive={pathname === "/roster-preferences"} />,
      label: "Roster preferences",
    },
    {
      divider: true,
    },
    {
      to: "/agencies",
      isActive: pathname === "/agencies",
      isHidden: !store.user?.agencyRegistrations?.length,
      icon: <AgenciesIcon isActive={pathname === "/agencies"} />,
      label: (
        <>
          {!!outstandingAgencies?.length && (
            <TimesheetsAlert number={outstandingAgencies.length} />
          )}
          <span>Agencies</span>
        </>
      ),
    },
    {
      to: "/organisations",
      isActive: pathname === "/organisations",
      isHidden: false,
      icon: <OrganisationsIcon isActive={pathname === "/organisations"} />,
      label: "Organisations",
    },
    {
      to: "/documents",
      isActive: pathname === "/documents",
      icon: <DocumentsIcon isActive={pathname === "/documents"} />,
      label: "Document upload",
    },
  ];
};

export const TimesheetsAlert = ({
  number,
  navigation,
}: {
  number: number;
  navigation?: boolean;
}) => {
  return (
    <div
      className={classNames(
        "absolute w-6 h-6 text-sm flex text-white rounded-full bg-red-6 items-center font-bold",
        navigation ? "top-[-2px] right-[-26px]" : "top-2 right-2"
      )}
    >
      <span className="flex justify-center flex-1">{number}</span>
    </div>
  );
};
