import { createRoot } from "react-dom/client";
import ReactHeap from "reactjs-heap";
import { datadogRum } from "@datadog/browser-rum";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features";
import "bootstrap/dist/css/bootstrap.css";
import "normalize.css/normalize.css";
import "react-notifications-component/dist/theme.css";
import "react-day-picker/lib/style.css";
import "react-image-lightbox/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "./dist/output.css";
import App from "./App";
import { GlobalWrappers } from "./GlobalWrappers";
import * as serviceWorker from "./serviceWorker";

const production = "https://api.patchwork.health";
const staging = "https://api.staging.patchwork.health";
const demo = "https://api.demo.patchwork.health";

const isStaging = process.env.REACT_APP_API === staging;

const getEnvForDatadog = () => {
  switch (process.env.REACT_APP_API) {
    case staging:
      return "staging";
    case demo:
      return "demo";
    case production:
      return "production";
    default:
      return "unknown";
  }
};

const datadogInit = () => {
  datadogRum.init({
    applicationId: "8460fcf7-eb54-43f8-ac71-a5f741e70eac",
    clientToken: "pub334db73fcc44062bcab95f6d5145ea6e",
    site: "datadoghq.eu",
    service: "web-worker",
    sessionSampleRate: 100,
    sessionReplaySampleRate: isStaging ? 0 : 20, // Disable session replay for staging, enable for demo and production
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    env: getEnvForDatadog(),
    allowedTracingUrls: [
      "https://api.patchwork.health",
      "https://api.staging.patchwork.health",
      "https://self-rostering.patchwork.health",
      "https://self-rostering.staging.patchwork.health",
      "https://service-plans.staging.patchwork.health",
      "https://service-plans.patchwork.health",
    ],
  });
};

const AppWithProviders = () => (
  <GlobalWrappers test={false}>
    <App />
  </GlobalWrappers>
);

const startApp = () => {
  datadogInit();
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();

  if (process.env.REACT_APP_HEAP_ID) {
    ReactHeap.initialize(process.env.REACT_APP_HEAP_ID);
  }

  const container = document.getElementById("root");

  if (!container) throw new Error("Failed to find the container element!");

  const root = createRoot(container);

  root.render(<AppWithProviders />);
};

startApp();
