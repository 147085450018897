import { useQuery } from "react-query";
import _ from "lodash";

import { servicePlanApiClient } from "helpers/kmonoApiClient";
import { SailFlag, useFeatureFlag } from "helpers/useFeatureFlags";

export interface ActivityName {
  id: string;
  name: string;
}

export const useGetShiftsActivities = ({
  eventIds,
}: {
  eventIds: number[];
}) => {
  const servicePlansEnabled = useFeatureFlag(SailFlag.servicePlansEnabled);

  const { shiftActivitiesApi } = servicePlanApiClient;

  const query = useQuery({
    enabled: eventIds.length > 0 && servicePlansEnabled,
    queryKey: ["getShiftsActivities", { eventIds }],
    queryFn: () =>
      shiftActivitiesApi.getApiShiftActivitiesActivities({
        eventId: eventIds.join(","),
      }),
  });
  const data = query.data || [];
  const activitiesForShift = (eventId: number) => {
    if (!servicePlansEnabled || !data || !eventId) return [];
    const foundForShift = data.find((item) => {
      return item.eventId === +eventId;
    });
    return foundForShift ? foundForShift.activities : [];
  };
  const activitiesLabelForShift = (
    eventId: number,
    oldActivity: string | undefined | null
  ) => {
    const activities = activitiesForShift(+eventId);
    if (servicePlansEnabled && activities.length === 0) {
      return oldActivity ?? "No activities";
    }
    if (!servicePlansEnabled) {
      return oldActivity ?? "No activities";
    }
    if (activities.length === 1) {
      return activities[0].name;
    }
    return `${activities.length} Activities`;
  };
  const eventIdsForActivities = (activityIds: string[]) => {
    return data
      .filter(
        (event) =>
          event.activities.filter((activity) =>
            activityIds.includes(activity.id)
          ).length > 0
      )
      .map((event) => +event.eventId);
  };
  const activityNames: ActivityName[] = _.uniqBy(
    data.flatMap((activities) =>
      activities.activities.map((activity) => ({
        name: activity.name,
        id: activity.id.toString(),
      }))
    ),
    "id"
  );

  return {
    ...query,
    data,
    activitiesForShift,
    activitiesLabelForShift,
    eventIdsForActivities,
    activityNames,
  };
};
