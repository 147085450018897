/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ApiCreateClaim } from "@patchworkhealth/expenses-api";
import {
  FormGrid,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
} from "@patchworkhealth/web-components";

import { DocumentsAwaiting } from "components/Documents/Documents";
import { ContractUploadedIcon } from "components/Expenses/expensesHelpers";
import ExpensesTable from "components/Expenses/ExpensesTable";
import Layout from "components/Layout/Layout";
import { BackIcon } from "components/Navigation/NavIcons";
import { FormikErrors } from "components/Style";
import { Button } from "components/UI/Button";
import { expensesApi, onReactQueryError } from "helpers/kmonoApiClient";
import { CloseIcon, UploadIcon } from "icons/documents";

export type ExpensesInputs = {
  page: number;
  status: {
    label: string;
    value: string;
  } | null;
};

const initInputs: ExpensesInputs = {
  page: 1,
  status: null,
};

function ExpensesPage() {
  const history = useHistory();
  const [inputs, setInputs] = useState(initInputs);
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const org = searchParams.get("org");
  const staffGroupId = searchParams.get("staff_group");
  const departmentId = searchParams.get("department");

  const { data: allClaims, isLoading } = useQuery({
    queryKey: ["ExpensesClaims"],
    queryFn: () => expensesApi.claimApi.getApiClaim(),
  });

  const { data: categoryData } = useQuery({
    enabled: !!departmentId,
    queryKey: ["DepartmentExpensesCategories", departmentId],
    queryFn: () =>
      expensesApi.categoryApi.getApiCategory({
        department: departmentId,
      }),
  });

  const allCategories = categoryData ?? [];

  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (apiCreateClaim: ApiCreateClaim) =>
      expensesApi.claimApi.postApiClaim({
        apiCreateClaim,
      }),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: ["DepartmentExpensesCategories", departmentId],
        })
        .then(() => {
          toast.success("Expense Claim Created");
          window.location.href = "/expenses";
        });
    },
    onError: onReactQueryError,
  });

  const [category, setCategory] = useState(null); // Add state variable to hold expense type

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      shift: "",
      category: null,
      amount: 0,
      notes: "",
      receiptFile: null,
      units: 0,
    },
    validationSchema: Yup.object({
      shift: Yup.string().required("Required"),
      category: Yup.object().required("Required"),
      notes: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      let amount = 0;

      if (category.expenseType === "fixed") {
        amount = values.amount;
      } else if (category.expenseType === "calculated") {
        amount = values.units * (category.paymentPerUnit ?? 0);
      }

      if (values.amount === 0 && category.expenseType === "fixed") {
        toast.error("Amount  should be greater than 0", { id: toastId });
        return;
      }

      if (values.units === 0 && category.expenseType === "calculated") {
        toast.error("Units  should be greater than 0", { id: toastId });
        return;
      }

      createMutation.mutate({
        amount,
        categoryId: values?.category?.value ?? 0,
        notes: values.notes,
        shiftId: Number(values.shift),
      });
    },
  });

  useEffect(() => {
    if (id) {
      setInputs({ ...inputs, page: 2 });
      formik.setFieldValue("shift", id ?? "");
    }
  }, [id, org]);

  const claimsList = allClaims ?? [];

  const filteredCategories = allCategories.filter(
    (category) => category.organisationStaffGroupId === +staffGroupId
  );

  const uploadEvidenceFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const file = e.target.files?.[0];
    formik.setFieldValue("receiptFile", file);
  };

  if (inputs.page === 1) {
    return (
      <Layout headerValueText="Expenses">
        <p className="mt-8 text-center font-semibold">
          To submit an expense claim, please select a shift or timesheet from
          the menu’s on the left
        </p>
        <div className="mt-10 ">
          <ExpensesTable
            loading={isLoading}
            expenses={claimsList}
            inputs={inputs}
            setInputs={setInputs}
          />
          <div className="flex justify-center mt-10" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout headerValueText="Expenses" navigation="expenses">
      <div className="mx-auto max-w-[1200px]">
        <button
          className="w-40 mb-10 flex items-center text-black-5"
          onClick={() => {
            setInputs({ ...inputs, page: 1 });
            history.push("/expenses");
            formik.resetForm();
          }}
        >
          <BackIcon />
          Back
        </button>

        <div>
          <h2 className="font-bold text-[24px] mb-8">Submit new Expense</h2>
        </div>

        <div
          className="relative w-full px-4 mb-6 bg-white border max-w-[800px] border-gray-300 rounded-lg shadow-sm sm:px-8 py-7"
          key={123}
        >
          <div className="mb-10">
            <Form>
              <FormGrid columns={2}>
                <FormGroup>
                  <FormLabel>
                    Shift <span>*</span>
                  </FormLabel>
                  <FormInput
                    name="shift"
                    type="number"
                    disabled
                    onChange={formik.handleChange}
                    value={formik.values.shift}
                    placeholder="Enter Shift ID"
                  />
                  <FormikErrors
                    touch={formik.touched.shift}
                    err={formik.errors.shift}
                  />
                </FormGroup>
                <div />

                <FormGroup>
                  <FormLabel>
                    Expense Category <span>*</span>
                  </FormLabel>
                  <FormSelect
                    placeholder="Select Category"
                    type="text"
                    value={
                      !formik.values.category ? null : formik.values.category
                    }
                    options={filteredCategories.map((category) => ({
                      label: (
                        <div className="flex items-center">
                          <span>{category.name} </span>
                        </div>
                      ),
                      value: category.id,
                    }))}
                    onChange={(option) => {
                      formik.setFieldValue(
                        "category",
                        option !== null ? option : []
                      );
                      const selectedCategory = filteredCategories.find(
                        (cat) => cat.id === option?.value
                      );
                      setCategory(selectedCategory);
                    }}
                  />
                  <FormikErrors
                    touch={formik.touched.category}
                    err={formik.errors.category}
                  />
                </FormGroup>

                {filteredCategories
                  .filter(
                    (category) => category.id === formik.values.category?.value
                  )
                  .map((category) => {
                    if (category.expenseType === "fixed") {
                      return (
                        <FormGroup key={category}>
                          <FormLabel>
                            Amount (£) <span>*</span>
                          </FormLabel>
                          <FormInput
                            name="amount"
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.amount}
                            placeholder="Enter claim amount"
                          />
                          <FormikErrors
                            touch={formik?.touched?.amount}
                            err={formik?.errors?.amount}
                          />
                        </FormGroup>
                      );
                    } else if (category.expenseType === "calculated") {
                      return (
                        <>
                          <FormGroup>
                            <FormLabel>
                              Units <span>*</span>
                            </FormLabel>
                            <FormInput
                              name="units"
                              type="number"
                              onChange={formik.handleChange}
                              value={formik.values.units}
                              placeholder="Enter units"
                              min={0}
                            />
                            <FormikErrors
                              touch={formik?.touched?.units}
                              err={formik?.errors?.units}
                            />
                          </FormGroup>

                          <FormGroup>
                            <FormLabel>Price Per Unit (£)</FormLabel>
                            <FormInput
                              name="pricePerUnit"
                              type="number"
                              onChange={formik.handleChange}
                              value={category.paymentPerUnit ?? 0}
                              placeholder="Enter price per unit"
                              disabled // Add disabled attribute here
                            />
                          </FormGroup>
                          <FormGroup>
                            <p className="text-[22px] mt-8 ml-4 font-semibold">
                              Total Amount £
                              {formik.values.units *
                                (Number(category?.paymentPerUnit?.toFixed(2)) ??
                                  0)}
                            </p>
                          </FormGroup>
                        </>
                      );
                    }
                    return null;
                  })}
              </FormGrid>
              <FormGroup className="my-8">
                <FormLabel isRequired>Notes</FormLabel>
                <FormInput
                  as="textarea"
                  name="notes"
                  placeholder="Please add any further details. For mileage, add start and end location"
                  rows={5}
                  onChange={formik.handleChange}
                  value={formik.values.notes}
                />
                <FormikErrors
                  touch={formik?.touched?.notes}
                  err={formik?.errors?.notes}
                />
              </FormGroup>

              <FormGroup>
                {formik.values.receiptFile ? (
                  <div className="flex items-center font-semibold mb-4">
                    Receipt Uploaded <ContractUploadedIcon />
                  </div>
                ) : (
                  <>
                    <FormLabel isRequired={category?.evidenceRequired ?? false}>
                      Upload Receipt
                    </FormLabel>
                    <DocumentsAwaiting>
                      <div
                        className="type mt-[-20px]"
                        key={123}
                        data-testid="other_doc"
                      >
                        <label
                          className="custom-doc-upload"
                          data-testid="doc_upload"
                        >
                          <UploadIcon />
                          <input
                            type="file"
                            defaultValue={""}
                            onChange={(e) => uploadEvidenceFunction(e)}
                            className="documents__value"
                          />
                          <i className="fa fa-cloud-upload"></i> Upload File
                        </label>
                        <div className="type__footer">
                          <p data-testid="doc_title">Receipt</p>
                          <CloseIcon />
                        </div>
                      </div>
                    </DocumentsAwaiting>
                  </>
                )}
              </FormGroup>

              <div className="flex justify-end mb-[-40px] border-t pt-4">
                <Button
                  type="button"
                  variant="blue"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ExpensesPage;
