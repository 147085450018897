import { classNames } from "helpers/newHelpers";

export const ExpensesIcon = () => (
  <svg
    width="220"
    height="168"
    viewBox="0 0 220 168"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4401_4774)">
      <path
        d="M20.3025 167.738L20.2922 167.804C20.28 167.739 20.2683 167.673 20.2572 167.608C20.2368 167.502 20.2191 167.396 20.2025 167.29C19.5419 163.132 19.9054 158.875 21.2616 154.889C21.5223 154.111 21.8192 153.347 22.1523 152.598C23.4263 149.695 25.2323 147.057 27.4772 144.819C28.2061 144.073 29.0377 143.435 29.9469 142.924C30.0804 142.853 30.2146 142.786 30.3518 142.725C31.1926 142.317 32.1393 142.18 33.0614 142.332C33.3963 142.395 33.721 142.504 34.0261 142.656C34.1331 142.708 34.2361 142.765 34.3371 142.826C34.9535 143.23 35.4797 143.757 35.8829 144.374C36.2862 144.991 36.5577 145.684 36.6804 146.411C36.8556 147.495 36.8155 148.602 36.5626 149.671C36.5409 149.773 36.5175 149.874 36.4925 149.976C36.3132 150.688 36.0879 151.387 35.818 152.07C33.1163 159.021 27.3103 165.187 20.3025 167.738Z"
        fill="#F2F2F2"
      />
      <path
        d="M34.0083 142.678C31.9002 145.707 29.9088 148.813 28.0341 151.995C26.1616 155.175 24.4117 158.423 22.7841 161.738C21.8736 163.593 21.003 165.467 20.1721 167.36C20.0801 167.569 20.3905 167.752 20.4836 167.539C21.9675 164.161 23.5777 160.842 25.3144 157.582C27.05 154.325 28.9075 151.138 30.8869 148.019C31.9944 146.275 33.1387 144.554 34.3198 142.858C34.4519 142.668 34.1399 142.489 34.0083 142.678Z"
        fill="white"
      />
      <path
        d="M36.2641 149.689C34.6799 151.747 32.3481 153.099 29.7742 153.45C29.0561 153.547 28.3291 153.562 27.6078 153.492C27.377 153.471 27.3753 153.83 27.6042 153.852C30.2888 154.078 32.9541 153.236 35.0215 151.508C35.6014 151.024 36.1229 150.474 36.5756 149.868C36.7142 149.684 36.4017 149.506 36.2641 149.689Z"
        fill="white"
      />
      <path
        d="M22.7341 160.855C22.3657 160.331 22.0597 159.765 21.8222 159.17C21.2795 157.812 21.0869 156.341 21.262 154.889C21.5227 154.111 21.8196 153.348 22.1527 152.598C22.1729 152.628 22.185 152.662 22.188 152.697C22.1909 152.733 22.1844 152.769 22.1693 152.801C21.9348 153.363 21.7661 153.951 21.6668 154.552C21.3102 156.695 21.8052 158.893 23.0462 160.677C23.0619 160.696 23.0724 160.72 23.0765 160.744C23.0807 160.769 23.0785 160.794 23.0701 160.818C23.0562 160.849 23.0344 160.876 23.0068 160.896C22.9792 160.916 22.9468 160.929 22.9128 160.933C22.8788 160.937 22.8444 160.931 22.8131 160.918C22.7817 160.904 22.7545 160.883 22.7341 160.855Z"
        fill="white"
      />
      <path
        d="M30.0007 142.696C29.5671 144.424 29.6962 146.245 30.3693 147.893C30.3931 147.935 30.4322 147.965 30.4781 147.977C30.524 147.99 30.573 147.984 30.6145 147.96C30.655 147.936 30.6846 147.897 30.697 147.851C30.7095 147.806 30.7039 147.757 30.6814 147.715C30.0512 146.151 29.9343 144.426 30.3475 142.791C30.3582 142.746 30.3509 142.698 30.3271 142.658C30.3033 142.618 30.2648 142.588 30.2198 142.576C30.1748 142.564 30.1268 142.57 30.0859 142.592C30.045 142.614 30.0145 142.652 30.0007 142.696Z"
        fill="white"
      />
      <path
        d="M20.258 159.057L20.2798 159.121C20.2389 159.069 20.1977 159.016 20.1575 158.963C20.0904 158.879 20.0256 158.794 19.9614 158.707C17.4445 155.332 15.7889 151.393 15.138 147.234C15.0074 146.424 14.9154 145.609 14.8622 144.791C14.6419 141.629 15.0154 138.454 15.9635 135.429C16.2624 134.43 16.7025 133.479 17.2703 132.604C17.3556 132.48 17.4433 132.358 17.5361 132.239C18.0913 131.488 18.8659 130.927 19.7533 130.633C20.0791 130.534 20.4173 130.479 20.758 130.472C20.8769 130.468 20.9947 130.471 21.1125 130.478C21.846 130.55 22.5569 130.772 23.2006 131.131C23.8442 131.49 24.4068 131.978 24.8531 132.564C25.5118 133.443 25.991 134.442 26.2634 135.506C26.2916 135.607 26.3181 135.707 26.3431 135.809C26.5151 136.523 26.6406 137.247 26.7189 137.977C27.5555 145.387 25.2788 153.544 20.258 159.057Z"
        fill="#F2F2F2"
      />
      <path
        d="M20.7532 130.5C20.2936 134.161 19.9729 137.837 19.7913 141.526C19.6105 145.211 19.5697 148.9 19.6689 152.592C19.7245 154.658 19.8241 156.722 19.9677 158.784C19.9836 159.012 20.343 159.029 20.327 158.798C20.0713 155.117 19.9551 151.429 19.9785 147.736C20.0025 144.046 20.1665 140.361 20.4705 136.68C20.6407 134.62 20.8547 132.565 21.1125 130.514C21.1413 130.285 20.7819 130.272 20.7532 130.5Z"
        fill="white"
      />
      <path
        d="M26.0075 135.661C25.561 138.22 24.1238 140.499 22.0075 142.005C21.4171 142.426 20.78 142.776 20.1089 143.05C19.8944 143.137 20.0601 143.457 20.2728 143.37C22.755 142.323 24.7241 140.339 25.7525 137.849C26.0409 137.151 26.247 136.421 26.3668 135.675C26.4038 135.448 26.0443 135.435 26.0075 135.661Z"
        fill="white"
      />
      <path
        d="M19.2134 151.833C18.6435 151.54 18.1097 151.181 17.6228 150.764C16.5116 149.814 15.6572 148.6 15.1381 147.234C15.0075 146.424 14.9155 145.609 14.8623 144.791C14.8939 144.808 14.9205 144.832 14.9396 144.863C14.9588 144.893 14.9697 144.927 14.9713 144.963C15.0248 145.57 15.1485 146.169 15.3398 146.747C16.0198 148.811 17.4793 150.527 19.407 151.53C19.4299 151.54 19.4499 151.556 19.4651 151.576C19.4803 151.596 19.4901 151.619 19.4936 151.644C19.4958 151.678 19.489 151.712 19.474 151.743C19.4589 151.774 19.4361 151.8 19.4077 151.819C19.3794 151.838 19.3465 151.849 19.3125 151.852C19.2784 151.854 19.2442 151.848 19.2134 151.833Z"
        fill="white"
      />
      <path
        d="M17.212 132.378C17.6306 134.109 18.5908 135.661 19.953 136.809C19.9932 136.834 20.0418 136.843 20.0883 136.833C20.1348 136.822 20.1754 136.794 20.2012 136.754C20.2258 136.714 20.2338 136.665 20.2236 136.619C20.2134 136.573 20.1858 136.532 20.1466 136.506C18.8616 135.413 17.9569 133.941 17.5631 132.301C17.5515 132.255 17.5228 132.216 17.4831 132.192C17.4434 132.168 17.3957 132.159 17.3502 132.169C17.3046 132.18 17.2647 132.207 17.239 132.246C17.2132 132.285 17.2035 132.332 17.212 132.378Z"
        fill="white"
      />
      <path
        d="M20.4144 167.782L20.4671 167.824C20.4048 167.802 20.3413 167.78 20.279 167.757C20.1771 167.722 20.0765 167.685 19.9758 167.647C16.0369 166.158 12.5205 163.731 9.73151 160.577C9.18508 159.965 8.66901 159.328 8.18331 158.667C6.29442 156.121 4.89872 153.245 4.0683 150.186C3.78208 149.183 3.64046 148.145 3.64773 147.102C3.65252 146.951 3.66089 146.801 3.67529 146.651C3.73808 145.719 4.08851 144.829 4.67828 144.104C4.89908 143.845 5.15474 143.617 5.43799 143.427C5.53622 143.36 5.63691 143.299 5.73996 143.242C6.3966 142.907 7.11527 142.711 7.85097 142.667C8.58668 142.623 9.32357 142.731 10.0155 142.985C11.0437 143.371 11.9859 143.955 12.7884 144.704C12.8662 144.774 12.9429 144.845 13.0185 144.917C13.548 145.425 14.0439 145.968 14.5031 146.541C19.1993 152.334 21.675 160.433 20.4144 167.782Z"
        fill="#F2F2F2"
      />
      <path
        d="M5.44794 143.454C7.03285 146.786 8.74233 150.056 10.5764 153.262C12.4093 156.464 14.362 159.595 16.4344 162.652C17.5939 164.363 18.7895 166.048 20.0212 167.708C20.1575 167.892 20.4697 167.713 20.3316 167.527C18.1332 164.563 16.0492 161.518 14.0795 158.394C12.1121 155.272 10.2651 152.078 8.53846 148.813C7.57254 146.986 6.64584 145.139 5.75835 143.273C5.65906 143.064 5.34901 143.246 5.44794 143.454Z"
        fill="white"
      />
      <path
        d="M12.6555 144.973C13.6575 147.369 13.6744 150.064 12.7026 152.472C12.4315 153.144 12.0834 153.783 11.6653 154.375C11.5319 154.564 11.8435 154.744 11.9758 154.556C13.5034 152.337 14.094 149.606 13.6194 146.954C13.4862 146.21 13.2669 145.485 12.9659 144.791C12.8745 144.58 12.5647 144.762 12.6555 144.973Z"
        fill="white"
      />
      <path
        d="M15.642 162.258C15.0038 162.317 14.3608 162.302 13.7259 162.213C12.2781 162.011 10.9042 161.448 9.73081 160.577C9.18438 159.964 8.66832 159.328 8.18262 158.667C8.21822 158.664 8.25397 158.67 8.28633 158.686C8.31869 158.701 8.34655 158.724 8.36716 158.753C8.73915 159.236 9.16579 159.674 9.63854 160.058C11.3232 161.431 13.4774 162.091 15.642 161.898C15.6667 161.894 15.692 161.897 15.7155 161.905C15.739 161.914 15.7599 161.928 15.7762 161.947C15.7964 161.975 15.8091 162.007 15.813 162.041C15.8168 162.075 15.8117 162.11 15.7981 162.141C15.7846 162.172 15.763 162.2 15.7356 162.22C15.7083 162.241 15.676 162.253 15.642 162.258Z"
        fill="white"
      />
      <path
        d="M3.47639 146.942C4.76157 148.175 6.40685 148.966 8.17277 149.2C8.26982 149.213 8.3516 149.108 8.35251 149.02C8.35139 148.973 8.33209 148.928 8.29862 148.894C8.26516 148.861 8.22009 148.841 8.17277 148.84C6.50138 148.611 4.94589 147.858 3.73059 146.688C3.56397 146.527 3.30944 146.781 3.47639 146.942Z"
        fill="white"
      />
      <path
        d="M44.9515 120.377C45.3025 120.136 45.5965 119.822 45.8128 119.455C46.0291 119.089 46.1624 118.679 46.2032 118.256C46.2441 117.832 46.1916 117.405 46.0494 117.004C45.9072 116.602 45.6787 116.237 45.3802 115.934L46.5095 90.7292L40.7598 91.3745L41.0938 116.028C40.6175 116.564 40.3541 117.255 40.3535 117.972C40.353 118.688 40.6153 119.38 41.0908 119.916C41.5663 120.452 42.2219 120.795 42.9335 120.88C43.645 120.965 44.363 120.786 44.9515 120.377Z"
        fill="#CDCDCD"
      />
      <path
        d="M55.4404 163.043L58.713 163.618L61.9473 151.272L57.6595 150.423L55.4404 163.043Z"
        fill="#ECECEC"
      />
      <path
        d="M64.4893 167.766L54.2045 165.958L54.9031 161.985L61.2142 163.094C61.736 163.186 62.2347 163.38 62.6817 163.664C63.1287 163.949 63.5153 164.318 63.8194 164.752C64.1235 165.186 64.3392 165.675 64.4541 166.192C64.5691 166.709 64.581 167.244 64.4893 167.766Z"
        fill="#9FA0A2"
      />
      <path
        d="M46.4585 164.681L49.7812 164.681L51.3618 151.867L46.458 151.868L46.4585 164.681Z"
        fill="#ECECEC"
      />
      <path
        d="M56.1885 167.765L45.746 167.766L45.7458 163.732L52.1537 163.732C53.2237 163.732 54.25 164.157 55.0066 164.913C55.7633 165.67 56.1884 166.696 56.1885 167.765Z"
        fill="#9FA0A2"
      />
      <path
        d="M60.5927 161.172C60.5408 161.172 60.489 161.169 60.4375 161.163L56.5267 160.841C56.3487 160.82 56.1767 160.763 56.0214 160.674C55.866 160.585 55.7304 160.465 55.6229 160.321C55.5153 160.178 55.4381 160.014 55.3958 159.84C55.3535 159.665 55.3471 159.484 55.377 159.308L58.9882 139.072L56.5481 126.208C56.5282 126.103 56.4711 126.009 56.3875 125.944C56.3039 125.878 56.1993 125.845 56.093 125.85C55.9867 125.855 55.8859 125.899 55.8092 125.972C55.7325 126.046 55.6851 126.145 55.6757 126.251L52.6252 160.563C52.5917 160.907 52.4257 161.225 52.1623 161.45C51.8989 161.674 51.5586 161.787 51.2133 161.765L47.5289 161.628C47.206 161.605 46.9027 161.465 46.6761 161.234C46.4496 161.003 46.3156 160.697 46.2994 160.373L46.0518 119.249L65.1533 116.862L66.4877 137.467L66.4824 137.488L61.8776 160.162C61.8075 160.45 61.6427 160.706 61.4098 160.889C61.1768 161.072 60.8891 161.172 60.5927 161.172Z"
        fill="#9FA0A2"
      />
      <path
        d="M52.3488 74.3962C56.0251 74.3962 59.0053 71.4165 59.0053 67.7409C59.0053 64.0654 56.0251 61.0857 52.3488 61.0857C48.6726 61.0857 45.6924 64.0654 45.6924 67.7409C45.6924 71.4165 48.6726 74.3962 52.3488 74.3962Z"
        fill="#CDCDCD"
      />
      <path
        d="M58.6216 122.255C57.5805 122.261 56.5595 121.969 55.6792 121.413C52.4548 119.399 48.7925 120.199 46.8964 120.856C46.7083 120.922 46.5076 120.944 46.3096 120.922C46.1115 120.899 45.9211 120.832 45.7526 120.726C45.586 120.621 45.4452 120.48 45.3408 120.314C45.2364 120.147 45.1711 119.959 45.1497 119.763L41.7017 88.9745C41.1239 83.816 44.2319 78.9659 49.0917 77.4423C49.2743 77.385 49.4599 77.3307 49.6487 77.2794C51.161 76.8694 52.7448 76.7958 54.2887 77.0637C55.8325 77.3316 57.2989 77.9345 58.5846 78.83C59.8943 79.7354 60.9895 80.9167 61.7933 82.2909C62.5971 83.665 63.09 85.1986 63.2371 86.7837L66.1398 117.779C66.1596 117.98 66.1325 118.182 66.0607 118.371C65.9889 118.559 65.8743 118.728 65.7259 118.865C64.7084 119.806 61.7551 122.255 58.6216 122.255Z"
        fill="#7C7D80"
      />
      <path
        d="M48.1221 95.577L40.343 94.7218C40.1217 94.6975 39.9082 94.6258 39.7171 94.5115C39.526 94.3972 39.3618 94.2431 39.2356 94.0597C39.1095 93.8762 39.0243 93.6678 38.986 93.4485C38.9477 93.2292 38.9571 93.0042 39.0136 92.7888L40.9937 85.2433C41.1246 84.1118 41.699 83.0784 42.5908 82.3697C43.4827 81.6609 44.6193 81.3347 45.7513 81.4625C46.8834 81.5903 47.9186 82.1617 48.6299 83.0515C49.3412 83.9412 49.6707 85.0767 49.5459 86.2089L49.8399 93.979C49.8482 94.2014 49.8085 94.4231 49.7235 94.6288C49.6384 94.8346 49.51 95.0196 49.347 95.1712C49.184 95.3229 48.9902 95.4376 48.7788 95.5077C48.5674 95.5777 48.3435 95.6014 48.1221 95.577Z"
        fill="#7C7D80"
      />
      <path
        d="M66.1081 119.298C66.4276 119.017 66.6816 118.669 66.8521 118.279C67.0226 117.889 67.1055 117.467 67.0951 117.041C67.0846 116.616 66.9809 116.198 66.7914 115.817C66.6019 115.436 66.3311 115.101 65.9982 114.836L64.0813 89.6785L58.4473 90.9465L61.7544 115.446C61.3461 116.035 61.1679 116.753 61.2537 117.464C61.3395 118.175 61.6833 118.83 62.22 119.305C62.7566 119.78 63.4488 120.042 64.1654 120.04C64.882 120.039 65.5733 119.775 66.1081 119.298Z"
        fill="#CDCDCD"
      />
      <path
        d="M56.2869 93.9954C56.1238 93.8438 55.9954 93.6588 55.9103 93.4531C55.8253 93.2473 55.7856 93.0256 55.7941 92.8032L56.088 85.0331C55.9633 83.9009 56.2927 82.7654 57.0041 81.8757C57.7154 80.9859 58.7506 80.4145 59.8827 80.2867C61.0147 80.1589 62.1513 80.4851 63.0431 81.1939C63.935 81.9026 64.5094 82.9361 64.6403 84.0676L66.6204 91.613C66.6769 91.8284 66.6863 92.0534 66.648 92.2727C66.6096 92.492 66.5245 92.7005 66.3984 92.8839C66.2722 93.0674 66.108 93.2215 65.9169 93.3357C65.7258 93.45 65.5123 93.5217 65.2909 93.5461L57.5118 94.4012C57.2905 94.4257 57.0665 94.4021 56.8551 94.332C56.6437 94.262 56.4499 94.1472 56.2869 93.9954Z"
        fill="#7C7D80"
      />
      <path
        d="M51.953 74.6471C51.8347 74.647 51.7168 74.6332 51.6016 74.6061L51.5678 74.5979C45.7153 73.7027 44.422 70.3135 44.1381 68.898C43.8442 67.4326 44.1788 66.0181 44.9349 65.4687C44.5227 64.1673 44.5889 63.0135 45.132 62.0377C46.0793 60.3359 48.1352 59.7605 48.4109 59.6901C50.0527 58.4791 52.0171 59.2875 52.3746 59.4515C55.5505 58.2767 56.7643 59.2546 56.9839 59.4729C58.4035 59.7279 59.2689 60.2761 59.5562 61.1029C60.0958 62.6559 58.3893 64.5875 58.3164 64.669L58.2786 64.7113L55.7364 64.8323C55.4952 64.8438 55.2591 64.9059 55.0434 65.0145C54.8277 65.1231 54.6372 65.2759 54.4844 65.4628C54.3316 65.6497 54.2197 65.8667 54.1562 66.0997C54.0926 66.3326 54.0787 66.5763 54.1154 66.815C54.1842 67.1229 54.2711 67.4265 54.3757 67.7242C54.8099 69.0807 55.1351 70.2396 54.7156 70.6802C54.6205 70.7655 54.5034 70.8223 54.3776 70.8441C54.2517 70.8659 54.1223 70.8518 54.0041 70.8035C53.6066 70.6974 53.3368 70.7196 53.2023 70.8698C52.9935 71.1027 53.0573 71.6921 53.3817 72.5296C53.4835 72.7944 53.5109 73.0821 53.461 73.3613C53.411 73.6406 53.2856 73.9009 53.0983 74.114C52.9573 74.2803 52.7818 74.4141 52.5841 74.5061C52.3864 74.5982 52.1711 74.6463 51.953 74.6471Z"
        fill="#9FA0A2"
      />
      <path
        d="M130.564 95.9226H97.7713C96.5498 95.9212 95.3787 95.4355 94.515 94.5719C93.6513 93.7083 93.1654 92.5374 93.1641 91.3161V42.0457C93.1654 40.8244 93.6513 39.6535 94.515 38.7899C95.3787 37.9264 96.5498 37.4406 97.7713 37.4392H130.564C131.786 37.4406 132.957 37.9264 133.821 38.7899C134.684 39.6535 135.17 40.8244 135.172 42.0457V91.3161C135.17 92.5374 134.684 93.7083 133.821 94.5719C132.957 95.4355 131.786 95.9212 130.564 95.9226ZM97.7713 37.9811C96.6935 37.9824 95.6602 38.411 94.8981 39.173C94.136 39.9349 93.7073 40.9681 93.7061 42.0457V91.3161C93.7073 92.3937 94.136 93.4269 94.8981 94.1888C95.6602 94.9508 96.6935 95.3794 97.7713 95.3807H130.564C131.642 95.3794 132.675 94.9508 133.437 94.1888C134.2 93.4269 134.628 92.3937 134.63 91.3161V42.0457C134.628 40.9681 134.2 39.9349 133.437 39.173C132.675 38.411 131.642 37.9824 130.564 37.9811H97.7713Z"
        fill="#666666"
      />
      <path
        d="M125.55 61.1261H102.785C102.174 61.1254 101.589 60.8825 101.157 60.4507C100.725 60.0189 100.482 59.4335 100.481 58.8228V47.9841C100.482 47.3735 100.725 46.7881 101.157 46.3563C101.589 45.9245 102.174 45.6816 102.785 45.6809H125.55C126.161 45.6816 126.747 45.9245 127.179 46.3563C127.61 46.7881 127.853 47.3735 127.854 47.9841V58.8228C127.853 59.4335 127.61 60.0189 127.179 60.4507C126.747 60.8825 126.161 61.1254 125.55 61.1261Z"
        fill="#7C7D80"
      />
      <path
        d="M123.924 71.2874H104.411C103.836 71.2874 103.285 71.059 102.878 70.6525C102.472 70.2459 102.243 69.6946 102.243 69.1196C102.243 68.5447 102.472 67.9934 102.878 67.5868C103.285 67.1803 103.836 66.9519 104.411 66.9519H123.924C124.499 66.9519 125.051 67.1803 125.458 67.5868C125.864 67.9934 126.093 68.5447 126.093 69.1196C126.093 69.6946 125.864 70.2459 125.458 70.6525C125.051 71.059 124.499 71.2874 123.924 71.2874Z"
        fill="#CCCCCC"
      />
      <path
        d="M123.924 79.4163H104.411C103.836 79.4163 103.285 79.1879 102.878 78.7814C102.472 78.3748 102.243 77.8235 102.243 77.2486C102.243 76.6736 102.472 76.1223 102.878 75.7157C103.285 75.3092 103.836 75.0808 104.411 75.0808H123.924C124.499 75.0808 125.051 75.3092 125.458 75.7157C125.864 76.1223 126.093 76.6736 126.093 77.2486C126.093 77.8235 125.864 78.3748 125.458 78.7814C125.051 79.1879 124.499 79.4163 123.924 79.4163Z"
        fill="#CCCCCC"
      />
      <path
        d="M123.924 87.5454H104.411C103.836 87.5454 103.285 87.3171 102.878 86.9105C102.472 86.504 102.243 85.9526 102.243 85.3777C102.243 84.8028 102.472 84.2514 102.878 83.8449C103.285 83.4383 103.836 83.21 104.411 83.21H123.924C124.499 83.21 125.051 83.4383 125.458 83.8449C125.864 84.2514 126.093 84.8028 126.093 85.3777C126.093 85.9526 125.864 86.504 125.458 86.9105C125.051 87.3171 124.499 87.5454 123.924 87.5454Z"
        fill="#CCCCCC"
      />
      <path
        d="M210.514 123.561H177.722C176.5 123.56 175.329 123.074 174.465 122.211C173.601 121.347 173.116 120.176 173.114 118.955V69.6843C173.116 68.463 173.601 67.2922 174.465 66.4286C175.329 65.565 176.5 65.0793 177.722 65.0779H210.514C211.736 65.0793 212.907 65.565 213.771 66.4286C214.635 67.2922 215.12 68.463 215.122 69.6843V118.955C215.12 120.176 214.635 121.347 213.771 122.211C212.907 123.074 211.736 123.56 210.514 123.561ZM177.722 65.6198C176.644 65.621 175.61 66.0497 174.848 66.8116C174.086 67.5736 173.658 68.6067 173.656 69.6843V118.955C173.658 120.032 174.086 121.066 174.848 121.828C175.61 122.589 176.644 123.018 177.722 123.019H210.514C211.592 123.018 212.626 122.589 213.388 121.828C214.15 121.066 214.579 120.032 214.58 118.955V69.6843C214.579 68.6067 214.15 67.5736 213.388 66.8116C212.626 66.0497 211.592 65.621 210.514 65.6198H177.722Z"
        fill="#666666"
      />
      <path
        d="M203.875 77.2486H184.361C183.786 77.2486 183.235 77.0202 182.828 76.6136C182.422 76.2071 182.193 75.6557 182.193 75.0808C182.193 74.5059 182.422 73.9545 182.828 73.548C183.235 73.1415 183.786 72.9131 184.361 72.9131H203.875C204.45 72.9131 205.001 73.1415 205.408 73.548C205.814 73.9545 206.043 74.5059 206.043 75.0808C206.043 75.6557 205.814 76.2071 205.408 76.6136C205.001 77.0202 204.45 77.2486 203.875 77.2486Z"
        fill="#CCCCCC"
      />
      <path
        d="M203.875 85.3777H184.361C183.786 85.3777 183.235 85.1493 182.828 84.7428C182.422 84.3363 182.193 83.7849 182.193 83.21C182.193 82.6351 182.422 82.0837 182.828 81.6772C183.235 81.2706 183.786 81.0422 184.361 81.0422H203.875C204.45 81.0422 205.001 81.2706 205.408 81.6772C205.814 82.0837 206.043 82.6351 206.043 83.21C206.043 83.7849 205.814 84.3363 205.408 84.7428C205.001 85.1493 204.45 85.3777 203.875 85.3777Z"
        fill="#CCCCCC"
      />
      <path
        d="M205.501 106.107H182.735C182.125 106.106 181.539 105.863 181.107 105.431C180.675 104.999 180.432 104.414 180.432 103.803V92.9646C180.432 92.354 180.675 91.7686 181.107 91.3368C181.539 90.905 182.125 90.6621 182.735 90.6614H205.501C206.111 90.6621 206.697 90.905 207.129 91.3368C207.561 91.7686 207.804 92.354 207.804 92.9646V103.803C207.804 104.414 207.561 104.999 207.129 105.431C206.697 105.863 206.111 106.106 205.501 106.107Z"
        fill="#E6E6E6"
      />
      <path
        d="M203.875 115.726H184.361C183.786 115.726 183.235 115.498 182.828 115.091C182.422 114.685 182.193 114.133 182.193 113.558C182.193 112.983 182.422 112.432 182.828 112.026C183.235 111.619 183.786 111.391 184.361 111.391H203.875C204.45 111.391 205.001 111.619 205.408 112.026C205.814 112.432 206.043 112.983 206.043 113.558C206.043 114.133 205.814 114.685 205.408 115.091C205.001 115.498 204.45 115.726 203.875 115.726Z"
        fill="#CCCCCC"
      />
      <path
        d="M190.459 45.2517H157.666C156.444 45.2503 155.273 44.7646 154.41 43.901C153.546 43.0374 153.06 41.8665 153.059 40.6452V10.0715C153.06 8.85025 153.546 7.67938 154.41 6.8158C155.273 5.95222 156.444 5.46646 157.666 5.46509H190.459C191.68 5.46646 192.851 5.95222 193.715 6.8158C194.579 7.67938 195.065 8.85025 195.066 10.0715V40.6452C195.065 41.8665 194.579 43.0374 193.715 43.901C192.851 44.7646 191.68 45.2503 190.459 45.2517ZM157.666 6.00702C156.588 6.00824 155.555 6.43686 154.793 7.19884C154.031 7.96082 153.602 8.99394 153.601 10.0715V40.6452C153.602 41.7228 154.031 42.756 154.793 43.5179C155.555 44.2799 156.588 44.7085 157.666 44.7098H190.459C191.537 44.7085 192.57 44.2799 193.332 43.5179C194.094 42.756 194.523 41.7228 194.524 40.6452V10.0715C194.523 8.99394 194.094 7.96082 193.332 7.19884C192.57 6.43686 191.537 6.00824 190.459 6.00702H157.666Z"
        fill="#666666"
      />
      <path
        d="M183.819 35.6551H164.306C163.731 35.6551 163.179 35.4267 162.773 35.0201C162.366 34.6136 162.138 34.0622 162.138 33.4873C162.138 32.9124 162.366 32.361 162.773 31.9545C163.179 31.548 163.731 31.3196 164.306 31.3196H183.819C184.394 31.3196 184.945 31.548 185.352 31.9545C185.759 32.361 185.987 32.9124 185.987 33.4873C185.987 34.0622 185.759 34.6136 185.352 35.0201C184.945 35.4267 184.394 35.6551 183.819 35.6551Z"
        fill="#CCCCCC"
      />
      <path
        d="M183.819 27.5262H164.306C163.731 27.5262 163.179 27.2978 162.773 26.8912C162.366 26.4847 162.138 25.9333 162.138 25.3584C162.138 24.7835 162.366 24.2321 162.773 23.8256C163.179 23.4191 163.731 23.1907 164.306 23.1907H183.819C184.394 23.1907 184.945 23.4191 185.352 23.8256C185.759 24.2321 185.987 24.7835 185.987 25.3584C185.987 25.9333 185.759 26.4847 185.352 26.8912C184.945 27.2978 184.394 27.5262 183.819 27.5262Z"
        fill="#CCCCCC"
      />
      <path
        d="M183.819 19.397H164.306C163.731 19.397 163.179 19.1686 162.773 18.7621C162.366 18.3556 162.138 17.8042 162.138 17.2293C162.138 16.6543 162.366 16.103 162.773 15.6964C163.179 15.2899 163.731 15.0615 164.306 15.0615H183.819C184.394 15.0615 184.945 15.2899 185.352 15.6964C185.759 16.103 185.987 16.6543 185.987 17.2293C185.987 17.8042 185.759 18.3556 185.352 18.7621C184.945 19.1686 184.394 19.397 183.819 19.397Z"
        fill="#CCCCCC"
      />
      <path
        d="M132.462 44.9806C135.904 44.9806 138.695 42.1904 138.695 38.7484C138.695 35.3064 135.904 32.5161 132.462 32.5161C129.019 32.5161 126.229 35.3064 126.229 38.7484C126.229 42.1904 129.019 44.9806 132.462 44.9806Z"
        fill="#7C7D80"
      />
      <path
        d="M131.844 41.1258C131.704 41.1261 131.567 41.0808 131.455 40.9967L131.448 40.9914L129.983 39.8701C129.915 39.8182 129.858 39.7534 129.815 39.6794C129.772 39.6054 129.744 39.5236 129.732 39.4387C129.721 39.3538 129.726 39.2675 129.748 39.1847C129.771 39.102 129.809 39.0244 129.861 38.9564C129.913 38.8884 129.978 38.8314 130.052 38.7886C130.126 38.7458 130.208 38.718 130.293 38.7069C130.378 38.6958 130.464 38.7016 130.547 38.7239C130.63 38.7463 130.707 38.7847 130.775 38.837L131.724 39.5645L133.966 36.6408C134.018 36.5729 134.083 36.516 134.157 36.4733C134.231 36.4305 134.313 36.4028 134.397 36.3916C134.482 36.3804 134.568 36.386 134.651 36.4081C134.733 36.4302 134.811 36.4683 134.879 36.5203L134.865 36.5392L134.879 36.5206C135.016 36.6257 135.105 36.7808 135.128 36.9519C135.15 37.1229 135.104 37.2959 134.999 37.4329L132.362 40.8719C132.301 40.9511 132.223 41.0153 132.133 41.0593C132.043 41.1034 131.944 41.1261 131.844 41.1258Z"
        fill="white"
      />
      <path
        d="M213.767 72.8903C217.209 72.8903 220 70.1 220 66.658C220 63.2161 217.209 60.4258 213.767 60.4258C210.324 60.4258 207.533 63.2161 207.533 66.658C207.533 70.1 210.324 72.8903 213.767 72.8903Z"
        fill="#666666"
      />
      <path
        d="M213.149 69.0355C213.009 69.0358 212.872 68.9904 212.76 68.9063L212.753 68.9011L211.288 67.7797C211.22 67.7279 211.163 67.6631 211.12 67.5891C211.076 67.515 211.048 67.4332 211.037 67.3484C211.026 67.2635 211.031 67.1772 211.053 67.0944C211.075 67.0117 211.113 66.9341 211.165 66.8661C211.218 66.7981 211.283 66.7411 211.357 66.6982C211.431 66.6554 211.513 66.6277 211.598 66.6166C211.683 66.6055 211.769 66.6113 211.852 66.6336C211.934 66.6559 212.012 66.6944 212.08 66.7467L213.029 67.4741L215.271 64.5504C215.323 64.4826 215.388 64.4257 215.462 64.3829C215.536 64.3402 215.617 64.3124 215.702 64.3012C215.787 64.2901 215.873 64.2957 215.956 64.3178C216.038 64.3399 216.115 64.378 216.183 64.43L216.169 64.4489L216.184 64.4303C216.32 64.5354 216.41 64.6905 216.433 64.8615C216.455 65.0326 216.409 65.2056 216.304 65.3426L213.667 68.7816C213.606 68.8608 213.527 68.9249 213.437 68.969C213.348 69.013 213.249 69.0358 213.149 69.0355Z"
        fill="white"
      />
      <path
        d="M193.982 12.4645C197.425 12.4645 200.216 9.67424 200.216 6.23226C200.216 2.79028 197.425 0 193.982 0C190.54 0 187.749 2.79028 187.749 6.23226C187.749 9.67424 190.54 12.4645 193.982 12.4645Z"
        fill="#666666"
      />
      <path
        d="M193.365 8.60973C193.224 8.60999 193.088 8.56465 192.976 8.48054L192.969 8.47532L191.504 7.35395C191.436 7.30211 191.378 7.23731 191.335 7.16329C191.292 7.08926 191.264 7.00746 191.253 6.92257C191.241 6.83769 191.247 6.75139 191.269 6.66863C191.291 6.58587 191.329 6.50827 191.381 6.44029C191.433 6.3723 191.498 6.31527 191.573 6.27246C191.647 6.22965 191.729 6.2019 191.814 6.19081C191.899 6.17972 191.985 6.18551 192.068 6.20784C192.15 6.23016 192.228 6.26859 192.296 6.32092L193.244 7.04835L195.487 4.12464C195.539 4.05683 195.603 3.99992 195.677 3.95717C195.751 3.91441 195.833 3.88665 195.918 3.87547C196.003 3.86429 196.089 3.8699 196.171 3.89199C196.254 3.91408 196.331 3.95221 196.399 4.00421L196.385 4.02313L196.399 4.00448C196.536 4.10961 196.626 4.26471 196.648 4.43574C196.671 4.60677 196.625 4.77977 196.52 4.9168L193.883 8.35578C193.822 8.43503 193.743 8.49916 193.653 8.5432C193.563 8.58724 193.465 8.61 193.365 8.60973Z"
        fill="white"
      />
      <path
        d="M103.528 168H0.271016C0.199138 168 0.130204 167.971 0.0793786 167.921C0.0285532 167.87 0 167.801 0 167.729C0 167.657 0.0285532 167.588 0.0793786 167.537C0.130204 167.487 0.199138 167.458 0.271016 167.458H103.528C103.6 167.458 103.669 167.487 103.72 167.537C103.771 167.588 103.799 167.657 103.799 167.729C103.799 167.801 103.771 167.87 103.72 167.921C103.669 167.971 103.6 168 103.528 168Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_4401_4774">
        <rect width="220" height="168" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export type StatusLabelFormatDetails = {
  [key: string]: {
    statusName: string;
    color: string;
  };
};

export const statusLabelFormatDetails: StatusLabelFormatDetails = {
  REJECTED: {
    statusName: "Rejected",
    color: "bg-red-1 text-red-5",
  },
  APPROVED: {
    statusName: "Approved",
    color: "bg-green-1 text-emerald-800",
  },
  DELETED: {
    statusName: "Deleted",
    color: "bg-grey-2 text-black-3",
  },
  PAID: {
    statusName: "Paid",
    color: "bg-black-4 text-white",
  },
  PENDING: {
    statusName: "Pending",
    color: "bg-orange-100 text-red-500",
  },
};

export const ExpensesStatus = ({
  status,
}: {
  status: keyof StatusLabelFormatDetails;
}) => {
  const labelFormatDetails = statusLabelFormatDetails[status];

  return (
    <span className="flex max-w-xs flex-col space-y-1">
      <span
        className={classNames(
          "line-clamp-2 min-w-20 rounded px-2 py-1 text-center font-semibold",
          labelFormatDetails.color
        )}
      >
        {labelFormatDetails.statusName}
      </span>
    </span>
  );
};

export const ContractUploadedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className="ml-2 mt-[-2px]"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 8.94C19.9896 8.84813 19.9695 8.75763 19.94 8.67V8.58C19.8919 8.47718 19.8278 8.38267 19.75 8.3L13.75 2.3C13.6673 2.22222 13.5728 2.15808 13.47 2.11C13.4402 2.10576 13.4099 2.10576 13.38 2.11C13.2784 2.05174 13.1662 2.01434 13.05 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V9C20 9 20 9 20 8.94ZM14 5.41L16.59 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V5.41ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H12V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H18V19ZM14.29 12.29L11 15.59L9.71 14.29C9.5217 14.1017 9.2663 13.9959 9 13.9959C8.7337 13.9959 8.4783 14.1017 8.29 14.29C8.1017 14.4783 7.99591 14.7337 7.99591 15C7.99591 15.2663 8.1017 15.5217 8.29 15.71L10.29 17.71C10.383 17.8037 10.4936 17.8781 10.6154 17.9289C10.7373 17.9797 10.868 18.0058 11 18.0058C11.132 18.0058 11.2627 17.9797 11.3846 17.9289C11.5064 17.8781 11.617 17.8037 11.71 17.71L15.71 13.71C15.8983 13.5217 16.0041 13.2663 16.0041 13C16.0041 12.7337 15.8983 12.4783 15.71 12.29C15.5217 12.1017 15.2663 11.9959 15 11.9959C14.7337 11.9959 14.4783 12.1017 14.29 12.29Z"
      fill="#316959"
    />
  </svg>
);
